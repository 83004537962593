.wrapPageHeader {
  .sendButton {
    background-color: #0b1e60;
    box-shadow: none;
    border: none;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 5px;

    @media screen and (max-width: 523px) {
      width: 100%;
    }

    & > span {
      font-weight: 500;
      font-family: "AvenirLTProBook";
    }
  }
}

.statisticsCard {
  margin-bottom: 20px;

  & > :first-child {
    border-bottom: none;
    padding: 0 16px 0 16px;

    & > div {
      & > div {
        padding-bottom: 0;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  & > :nth-child(2) {
    padding: 16px 22px;
  }

  .statisticsWrap {
    display: flex;
    justify-content: space-between;

    .statisticsData {
      // position: relative;

      // // min-width: 130px;
      // &::after {
      //     content: " ";
      //     width: 1px;
      //     height: 27px;
      //     background-color: #E5E5E5;
      //     position: absolute;
      //     right: 9px;
      //     top: 13px;

      // }

      .statisticsTitle {
        font-weight: 700;
        font-size: 14px;
        color: #6d6e71;
      }

      .statisticsCount {
        font-weight: 700;
        font-size: 16px;
        color: #4d72be;
      }
    }

    .statisticsData + .statisticsData {
      position: relative;

      &::after {
        content: " ";
        width: 1px;
        height: 27px;
        background-color: #e5e5e5;
        position: absolute;
        right: 9px;
        top: 13px;
      }
    }
  }
}

.modalTitle {
  font-weight: 700 !important;
  font-family: "AvenirLTProBook";
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.statusCard {
  border-bottom-left-radius: 0 !important;

  border-bottom-right-radius: 0 !important;
  border-radius: 5px;

  .statusCardHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    & > h5 {
      font-size: 20px;
      font-weight: 700;

      & > span {
        color: #b7b7b7;
        font-size: 18px;
        font-weight: 400;
      }
    }

    .titleDivider {
      background-color: black;
      margin: 0 2px;
      width: 2px;
      height: 20px;
    }
  }

  & > :first-child {
    padding: 16px;
  }

  .viewMoreBtn {
    color: #1890ff;
    padding: 0;
  }

  & > :nth-child(2) {
    padding: 0 16px 16px 16px;
  }

  .active {
    .statusCardInnerBoxes {
      background-color: #0b1e60 !important;

      & > h5 {
        color: white;
      }

      .jobsDescription {
        color: white;
      }
    }
  }

  .statusCardInnerBoxes {
    cursor: pointer;
    background: #eef2ff !important;
    border-radius: 5px;
    padding: 5px 18px 5px 18px;
    height: 100%;

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      padding: 10px 15px;
    }

    & > h5 {
      font-size: 24px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 0;
    }

    .jobsDescription {
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
    }

    .jobsDetailIcon {
      border: 1px solid #d6ddeb;
      border-radius: 50px;
      color: #26a4ff;
      height: 50px;
      min-width: 50px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin-left: 10px;
    }
  }
}

.cardCloseIcon {
  margin-top: 10px;

  & > i {
    font-size: 10px;
  }
}

.statusCountCard {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 20px;
  width: 100%;
  border: none;
  border-top: 1px solid #f0f0f0;

  & > :first-child {
    border-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;

    & > div {
      & > div {
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 0;
        padding-top: 10px;
        white-space: break-spaces;

        @media screen and (max-width: 350px) {
          font-size: 18px;
        }
      }

      & > :nth-child(2) {
        padding: 0;
      }
    }
  }

  & > :nth-child(2) {
    padding: 16px !important;
  }

  .StatusCountCardBoxes {
    border-radius: 5px;
    padding: 5px 18px 5px 18px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    // max-width: 232px;
    &.active {
      background-color: #4d72be !important;
      div,
      h5 {
        cursor: pointer;
        color: #fff !important;
      }
    }

    & > h5 {
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 0;
    }

    .jobsDescription {
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}

.jobCardWrap {
  margin-bottom: 20px;

  .bottomCard {
    margin-bottom: 24px;
    height: 100%;

    & > :first-child {
      padding: 16px;

      & > span {
        margin-bottom: 16px;
      }
    }

    .bottomCardInfoHeader {
      @media screen and (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
      }
    }

    .bottomCardInfo {
      flex-wrap: wrap;

      @media screen and (min-width: 768px) and (max-width: 1200px) {
        display: flex;
        justify-content: space-between;
      }

      & > div {
        & > span {
          margin-bottom: 16px;

          @media screen and (max-width: 768px) {
            width: 100% !important;
          }
        }
      }

      .jobCardHeader {
        margin-bottom: 16px;

        @media screen and (max-width: 575px) {
          flex-direction: column;
        }

        @media screen and (min-width: 1071px) and (max-width: 1200px) {
          gap: 16px;
        }

        @media screen and (min-width: 1072px) {
          display: flex;
          justify-content: space-between;
        }

        @media screen and (min-width: 614px) and (max-width: 767px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      .jobInfo {
        display: flex;
        margin-bottom: 0;

        @media screen and (max-width: 470px) {
          flex-wrap: wrap;
        }

        @media screen and (min-width: 470px) and (max-width: 614px) {
          justify-content: space-between;
        }

        @media screen and (min-width: 768px) and (max-width: 1200px) {
          margin-bottom: 16px;
        }

        & > div:not(:last-of-type) {
          margin-right: 16px;

          @media screen and (max-width: 470px) {
            margin-right: 0;
          }
        }

        .cancelJobModal {
          @media screen and (min-width: 471px) and (max-width: 613px) {
            width: 33.33%;
          }

          @media screen and (max-width: 470px) {
            width: 100%;
            margin-bottom: 16px;
          }

          .cancelJob {
            background-color: white;
            border-radius: 4px;
            box-shadow: none;

            &:not(:disabled) {
              color: #ff6550;
              border-color: #ff6550;
            }

            @media screen and (max-width: 613px) {
              width: 100%;
            }
          }
          .cancelBilling {
            background-color: white;
            border-radius: 4px;
            box-shadow: none;
            //#278dc0
            &:not(:disabled) {
              color: #0b1e60;
              border-color: #0b1e60;
            }

            @media screen and (max-width: 613px) {
              width: 100%;
            }
          }
        }

        .jobStatusOptions {
          width: 128px;

          @media screen and (min-width: 471px) and (max-width: 613px) {
            width: 33.33%;
          }

          @media screen and (max-width: 470px) {
            width: 100% !important;
            margin-bottom: 16px;
          }

          .jobStatus {
            margin-bottom: 0 !important;

            @media screen and (max-width: 470px) {
              width: 100%;
            }

            .jobStatusField {
              & > div {
                & > :nth-child(2) {
                  color: #161616 !important;
                }
              }

              & > span {
                font-size: 10px;
                color: #d5d5d5;
              }
            }
          }
        }

        .addCandidateModal {
          margin-right: 0;

          @media screen and (min-width: 471px) and (max-width: 613px) {
            width: 33.33%;
          }

          @media screen and (max-width: 470px) {
            width: 100%;
          }

          .addCandidate {
            border-radius: 4px;

            &:not(:disabled) {
              background: #0b1e60;
            }

            @media screen and (max-width: 613px) {
              width: 100%;
            }
          }

          @media screen and (min-width: 1200px) {
            margin-right: 16px;
          }
        }
      }

      .iconsModals {
        display: flex;

        & > div {
          @media screen and (max-width: 470px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 613px) {
          margin-top: 16px;
          justify-content: flex-end;
        }

        @media screen and (min-width: 768px) and (max-width: 1200px) {
          justify-content: flex-end;
        }

        @media screen and (min-width: 992px) and (max-width: 1005px) {
          margin-top: 0;
        }

        // &>div {
        //     margin-right: 16px;
        // }

        & > button + button {
          margin-left: 16px;
        }

        .appView {
          margin-left: 12px;
        }

        .bottomCardIcon {
          &:not(:disabled) {
            background-color: white;
            border-color: #d9d9d9;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          box-shadow: none;

          @media screen and (min-width: 471px) {
            max-width: 32px;
          }

          @media screen and (max-width: 470px) {
            width: 100%;
          }

          .settingIcon {
            color: #8c8c8c;
            font-size: 17px;
          }

          .appStoreIcon {
            font-size: 20px;
          }
        }

        & > :nth-child(3) {
          border: none;
          display: flex;
          align-items: center;
          width: 32px;
          justify-content: space-evenly;
        }

        .filterBtn {
          & > i {
            color: #8c8c8c;
          }
        }

        .addCandidateViewIcon {
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;

          .viewIcon {
            font-size: 18px;
            color: #515b6f;
            border: none;
          }
        }

        .active {
          color: #4640de !important;
          background: #f4f4f4;
        }
      }
    }
  }

  .reasonForMovingButton {
    & > span {
      color: #7c8493;
    }

    .emailIcon {
      color: #8c8c8c;
      font-size: 12px;
      margin-right: 6px;
    }
  }

  .closeAble {
    font-size: 10px;
    color: #7e84a3;
  }

  .ReasonForMoving {
    & > :nth-child(2) {
      & > div {
        border-bottom: none;
        padding: 16px 20px 0px 20px;

        & > div {
          color: #131523;
        }
      }

      & > :nth-child(4) {
        border-top: none;
        padding-bottom: 18px;
        padding-top: 0;

        & > :nth-child(2) {
          background-color: #0b1e60;
          border-radius: 5px;
          margin-left: 14px;

          & > span {
            color: white;
          }
        }
      }
    }

    .ReasonForMovingFrom {
      .rfmFields {
        margin-bottom: 20px !important;
      }

      .rfmTextArea {
        padding: 5px 7px;
      }

      .charactersLimit {
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.viewActivityModalButton {
  border-color: #d9d9d9;
  border-radius: 5px;
  padding: 0;
  height: 24px;

  & > span {
    font-weight: 400;
    font-size: 12px;
    padding: 1px 6px;
    color: #000000;
  }
}

.viewActivityModal {
  height: 100%;

  & > :nth-child(2) {
    & > :first-child {
      padding: 16px;
    }

    & > :nth-child(2) {
      border-top: none;
      padding: 0 36px 36px 36px;

      & > button {
        width: 84px;
        border-radius: 5px;
        border: 1.5px solid #000000;

        & > span {
          color: #0b1e60;
        }
      }
    }
  }

  .active {
    & > :first-child {
      color: #0b1e60;
    }
  }

  .clientsTabs {
    & > :first-child {
      &::before {
        border-bottom: none;
      }
    }
  }

  .tabHeader {
    @media screen and (max-width: 382px) {
      margin-bottom: 20px;
    }

    @media screen and (min-width: 576px) {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    // @media screen and (min-width:576px) and (max-width:199px) {
    //     margin-bottom: 20px;
    // }

    @media screen and (min-width: 1200px) and (max-width: 1420px) {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    .jobStatusOptions {
      width: 100%;

      @media screen and (max-width: 382px) {
        width: 100%;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 20px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1360px) {
        width: calc(50% - 10px);
        margin-bottom: 10px;
      }
    }

    .reloadBtn {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      background: white;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-self: flex-end;

      @media screen and (max-width: 575px) {
        margin-bottom: 20px;
        width: 100%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1360px) {
        width: 47%;
        margin-bottom: 10px;
      }

      @media screen and (min-width: 1361px) and (max-width: 1420px) {
        width: 100%;
      }

      &:not(:disabled) {
        & > i {
          color: gray;
          font-size: 15px;
        }
      }
    }
  }

  .hiringDate {
    // margin-bottom: 20px;

    .datePiker {
      margin-top: 3px;

      & > div {
        & > input {
          font-weight: 700;
          color: (0, 0, 0, 0.25);
        }
      }
    }
  }

  .clientChanges {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;

    .saveBtn {
      margin-right: 10px;
    }

    .cancelBtn {
      background: #f9f9f9;
      border: 1px solid #d9d9d9;
    }
  }

  .viewActivityHeader {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    position: relative;

    .ClientProfile {
      @media screen and (min-width: 372px) {
        display: flex;
      }

      & > span {
        min-width: 40px;
      }
    }

    & > :first-child {
      & > div {
        @media screen and (min-width: 372px) {
          margin-left: 12px;
        }

        @media screen and (max-width: 372px) {
          margin-top: 10px;
        }

        & > span {
          margin-top: 7px;
        }
      }
    }

    .ClientProfileInfo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > h5 {
        font-weight: 500;
        font-size: 16px;
        color: #25324b;
        margin-bottom: 0;
      }

      .seniorLogistician {
        padding-right: 10px;
        margin-bottom: 0;
        color: #7c8493;
      }

      .cvDownload {
        border-radius: 45px;
        width: 95px;
        height: 20px;
        display: flex;
        align-items: center;
        padding: 10px !important;
        line-height: 0%;
        font-weight: 700;
        font-size: 10px;

        .downIcon {
          margin-left: 2px;
        }
      }

      .editBtn {
        padding-left: 10px;
        padding-top: 5px;
      }
      .deleteBtn {
        color: #ff0000;
        padding: 0;
      }
    }

    .editButton {
      padding: 0;
      position: relative;
      top: -4px;
      right: 0;

      .editIcon {
        font-size: 10px;
        color: #bbbbbb;
        position: absolute;
        top: 10px;
      }
    }
  }

  .viewActivityFrom {
    & > div {
      width: 95%;
      margin-bottom: 20px;
    }

    .viewActivityLabel {
      font-weight: 400;
      font-size: 14px;
      color: #7c8493;
      margin-bottom: 5px;
      font-family: "AvenirLTProBook";
    }

    .viewActivityValue {
      color: #25324b;
      margin-bottom: 0;
      font-family: "AvenirLTProBook";
      font-weight: 600;
    }

    .viewActivityTags {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .fromDivider {
    margin-top: 0;
    margin-bottom: 14px;
    color: rgba(235, 235, 235, 1);
  }

  .commentHeading {
    color: #7c8493;
    margin-bottom: 20px;
  }

  .userCommentSkeleton {
    position: relative;
    // margin-bottom: 20px;
    // margin-top: 24px;
    max-width: 300px;
    width: 100%;
    margin: auto;
    left: -30px;

    @media screen and (min-width: 1200px) and (max-width: 1360px) {
      max-width: 243px;
      width: 100%;
    }

    @media screen and (min-width: 402px) and (max-width: 480px) {
      max-width: 240px;
      width: 100%;
    }

    @media screen and (max-width: 401px) {
      max-width: 168px;
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      margin-bottom: 20px;
    }

    & > :first-child {
      @media screen and (max-width: 576px) {
        margin-bottom: 10px;
      }

      @media screen and (min-width: 1200px) {
        margin-bottom: 10px;
      }
    }

    .secondComment {
      top: -13px;
      left: 60px;

      background-color: white;

      .secondCommentSkeleton {
        & > :first-child {
          & > div {
            & > span {
              background: #cdf06a !important;
            }
          }
        }
      }
    }

    .commentSkeletonWrap {
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.14);
      border-radius: 10px;
      padding: 12px 20px 10px 20px;
      height: 60px;
      position: relative;

      .commentSkeleton {
        gap: 0 !important;
        width: 100%;
        flex-wrap: wrap;

        & > :first-child {
          & > div {
            & > span {
              background: #f0c36a;
            }
          }
        }

        & > :first-child {
          position: absolute;
          top: 12px;
        }

        & > :not(:first-child) {
          width: 100%;
          margin-left: 50px;

          & > div {
            & > span {
              height: 12px !important;
              background: #eeeeee;

              @media screen and (max-width: 401px) {
                min-width: 62px;
              }
            }
          }
        }

        & > :nth-child(3) {
          & > div {
            width: 80% !important;

            & > span {
              background: #f4f4f4;
            }
          }
        }
      }
    }
  }

  .noComments {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-family: "AvenirLTProMedium";
  }

  .clientCommentDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .clientInfo {
      display: flex;
      align-items: center;

      h5 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        color: #25324b;
        margin-left: 15px;
        font-family: "AvenirLTProBook";
        font-weight: 600;
      }
    }
  }

  .commentDate {
    font-size: 10px;
    color: #515b6f;
    margin-left: 45px;
  }

  .clientComment {
    margin-left: 45px;
    margin-right: 20px;
    color: #000000;
    margin-bottom: 14px;
  }

  .commentSection {
    & > :first-child {
      & > :nth-child(2) {
        min-height: 0;
      }
    }

    margin-bottom: 0;
    margin-top: 20px;

    .sendButton {
      position: absolute;
      right: 8px;
      bottom: 32px;
      padding: 0;
      height: 20px;

      .sendIcon {
        font-size: 16px;
      }
    }

    .addComments {
      font-size: 12px;
      position: relative;
    }
  }

  .issueCredits {
    @media screen and (min-width: 381px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > div {
      @media screen and (max-width: 380px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      & > span {
        font-weight: 500;
        color: #25324b;
        font-family: "AvenirLTProMedium";
      }

      & > h5 {
        color: #25324b;
        font-weight: 700;
        font-family: "AvenirLTProHeavy";
      }
    }

    .submitBtns {
      .resubmitBtn {
        @media screen and (min-width: 768px) {
          margin-right: 10px;
        }
        font-family: "AvenirLTProMedium";

        @media screen and (max-width: 380px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      .submitBtn {
        font-family: "AvenirLTProMedium";

        @media screen and (max-width: 380px) {
          width: 100%;
        }
      }
    }
  }

  .invoiceWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .invoiceDetail {
      border: 1px solid #eef2ff;
      border-radius: 5px;
      padding: 16px 30px 16px 30px;
      width: calc(100% - 30px);

      @media screen and (max-width: 400px) {
        padding: 14px;
      }

      .invoiceDate {
        display: flex;
        justify-content: space-between;
        gap: 5px;

        & > h5 {
          margin: 0;
          font-weight: 600;
          color: #25324b;
          font-size: 14px;
          font-family: "AvenirLTProMedium";

          & > span {
            font-weight: 400;
            font-family: "AvenirLTProMedium";
          }
        }
      }
    }

    .invoiceDivider {
      margin: 10px 0;
    }

    .reloadBtn {
      padding: 0;
      color: orange;

      & > i {
        color: orange;
      }
    }
  }

  .ClientInfoWrap {
    & > h5 {
      color: #278dc0;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0;
      font-family: "AvenirLTProHeavy";
    }

    .clientInfo {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 10px;
      font-family: "AvenirLTProMedium";
    }

    .historyCalendar {
      & > i {
        color: #8c8c8c;
        font-size: 14px;
      }

      .dateInfo {
        color: #0b1e60;
        font-weight: 500;
        font-size: 12px;
        margin-left: 12px;
        font-family: "AvenirLTProMedium";
      }
    }
  }

  .divider {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.addNewCandidateOrderHistory {
  & > :first-child {
    padding: 40px;
  }

  .addCandidateOrderHistoryTitle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .orderHistoryIcon {
      border: 1px solid #d6ddeb;
      border-radius: 50px;
      min-width: 64px;
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      color: #26a4ff;
    }

    & > h5 {
      margin-bottom: 0;
      margin-left: 16px;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .addCandidateOrderHistory {
    text-align: center;
    padding-left: 80px;

    .addCandidateOpeningDate {
      .openingAddedTitle {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        font-weight: 400;
      }

      .orderHistoryValue {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 700;
        font-size: 24px;
      }
    }
  }
}

.jobCancelModal {
  & > :nth-child(2) {
    & > div {
      border-bottom: none;
      padding: 20px 20px 0px 20px;

      & > div {
        color: #e47d6e;
        font-family: "AvenirLTProBook";
        font-weight: 600;
        font-size: 20px;
      }
    }

    & > :nth-child(4) {
      border-top: none;
      padding-top: 0;
      padding-bottom: 20px;
    }

    & > button {
      margin-top: 4px;

      & > span {
        & > i {
          font-size: 10px;
        }
      }
    }
  }

  .jobCancelSubTitle {
    font-size: 14px;
    font-weight: 600;
    font-family: "AvenirLTProBook";
    margin-bottom: 22px;
    display: flex;
    color: #000000;

    .briefcaseIcon {
      font-size: 20px;
      color: #8c8c8c;
      margin-right: 10px;
    }
  }

  .cancelJobFrom {
    .jobFieldLabel {
      margin-bottom: 20px;

      & > div {
        & > label {
          font-size: 12px;
          font-family: "AvenirLTProBook";
          font-weight: 600;
        }
      }
    }

    .jobInputFields {
      font-size: 12px;
    }
  }

  .jobCancelButton {
    border-radius: 5px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  }

  .jobConfirmButton {
    border-radius: 5px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    margin-left: 14px !important;

    &:not(:disabled) {
      background-color: #e47d6e;
      color: white;
    }
  }
}

.addNewCandidateModal {
  & > :nth-child(2) {
    & > :nth-child(2) {
      border-bottom: none;

      & > div {
        color: #131523;
        font-family: "AvenirLTProBook";
        font-weight: 600;
        font-size: 20px;
      }
    }

    & > :nth-child(3) {
      padding: 18px 30px 40px 30px;
    }

    & > :nth-child(4) {
      padding-top: 0;
      border-top: none;
      padding-bottom: 30px;
    }

    & > button {
      margin-top: 5px;

      & > span {
        & > i {
          font-size: 10px;
        }
      }
    }
  }

  .addCandidateTitle {
    color: #131523;
    margin-bottom: 22px;
  }

  .addCandidateFrom {
    .addCandidateLabel {
      margin-bottom: 20px;
      font-size: 14px;
      font-family: "AvenirLTProBook";
    }

    .addCandidateInputFields {
      border-radius: 5px;
    }

    .rowDivider {
      margin-top: 0;
      margin-bottom: 18px;
      color: #e3e3e3;
    }

    .rowDivideLine {
      margin-top: 8px;
    }
  }

  .addNewCandidateCheckbox {
    margin-top: 6px;

    @media screen and (min-width: 450px) {
      display: flex;
      justify-content: space-between;
    }

    & > div {
      margin-bottom: 0;

      @media screen and (max-width: 450px) {
        margin-bottom: 10px;
      }
    }

    .oliviaCheckbox {
      & > :first-child {
        & > span {
          border-radius: 2px;
        }
      }
    }

    .jobFairCheckbox {
      & > :first-child {
        & > span {
          border-radius: 2px;
        }
      }

      @media screen and (max-width: 436px) {
        margin-left: 0;
      }
    }
  }

  .uploadCv {
    & > div {
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }

    .uploadButton {
      color: black;
      border: none;
      background-color: #f8f8fd;
      width: 178px;
      padding: 5px 16px;
      display: flex;
      align-items: center;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 5px;

      @media screen and (max-width: 575px) {
        width: 100%;
        justify-content: center;
      }
    }

    .uploadResume {
      margin-right: 8px;
      color: #8c8c8c;
      font-size: 12px;
    }
  }

  .candidateCancelButton {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
  }

  .candidateSubmitButton {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 5px;
    background: #0b1e60;
    color: white;
    margin-left: 14px !important;
  }
}
