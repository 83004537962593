.sitePageHeader {
  border: 1px solid rgb(235, 237, 240);
  background-color: #fff;

  &>div {
    &>div {
      span {
        font-size: 24px;
        font-weight: 500;
        font-family: "AvenirLTProBook";
      }
    }

    &>span {
      &>button {
        border-radius: 5px;
      }

      :nth-child(2) {
        background-color: #0B1E60;
        color: #fff;
        font-family: "AvenirLTProBook";
      }
    }
  }
}

.cardWrap {
  &>div {
    &>div {
      &>div {
        font-size: 16px;
        font-weight: 600;
        font-family: "AvenirLTProBook";
      }
    }
  }

  .commissionForm {
    max-width: 530px;
    width: 100%;

    label {
      font-size: 14px;
      font-weight: 600;
      font-family: "AvenirLTProBook";
    }

    input {
      border-radius: 5px;
      margin: auto;
    }

    .addClient {
      @media screen and (max-width:480px) {
        width: 100%;

      }
    }
  }
}

// .hiringYear{
//   span{
//     border-radius: 5px;
//     span{
//       color: #D9D9D9;
//     }
//   }
// }
//     .hiringPercentage{
//       span{
//         border-radius: 5px;
//         span{
//           color: #D9D9D9;
//         }
//       }
//       width: calc(50% - 8px);
//       display: inline-block;
//       margin: 0 8px;
//       @media only screen and (max-width: 992px) {
//         margin: 0 0 0 15px;

//       }
// }

.billingUnitForm {
  position: relative;

  .billingUnitRemoveBtn {

    .deleteBtn {
      position: absolute;
      right: 0;
      top: 15px;
      z-index: 1;
    }
  }

  .componentDivider {
    margin-top: 0;
  }
}