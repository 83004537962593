.configurationCheckbox {
    // margin-bottom: 12px;

    @media screen and (min-width:440px) {
        display: flex;
        justify-content: space-between;
    }
}

.radioTitle {
    font-weight: 600;
}

.radioGroup {
    margin-top: 10px;
    margin-bottom: 14px;
}

.addNewTitle {
    margin-bottom: 20px;
}

.selectTypeField {
    .ant-select-arrow .anticon {
        font-size: 10px;
    }
}