.assign-candidate-modal {
  .ant-modal-body {
    .instructions {
      margin-bottom: 1.5rem;
    }
    .select-job {
      .ant-select-selector {
        padding: 0 1rem;
      }
    }
    .rolesPermissionTransfer {
      .rolesTableTypography {
        font-weight: 600;
        color: black;
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .ant-transfer-list-content {
        .ant-transfer-list-content-item {
          border-bottom: 1px solid #f0f0f080;
          padding: 10px 12px;
          .candidate-names {
            .ant-typography {
              margin-bottom: 0;
              white-space: break-spaces;
              > :first-child {
                margin-left: 8px;
              }
            }
          }
        }
      }
      .ant-transfer-list-footer {
        display: flex;
        justify-content: end;
        padding: 6px 12px;
      }
    }
  }
}
