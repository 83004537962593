.cardFrom {
    max-width: 493px;
    width: 100%;
    padding: 40px 20px;
    // overflow-x: hidden;
    .cardTitle {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin-bottom: 42px;
        color: rgba(0, 0, 0, 0.85);
    }
    .loginForm {
        .formInputs {
            border-radius: 5px !important;

            &:hover {
                border-color: #d9d9d9;
            }

            input {
                color: rgba(0, 0, 0, 0.8);
                &::placeholder {
                    color: rgba(0, 0, 0, 0.25);
                }
            }

            Span {
                color: #8c8c8c;
                font-size: 14px;
            }
        }

        .inputCheckbox {
            & > span {
                & > span {
                    // background-color: #1890ff;
                    // border-color: #1890ff;
                    border-radius: 2px;
                }
            }
        }

        .forgotPassword {
            color: #1890ff;
            font-weight: 500;
            float: right;
            font-family: "Roboto", sans-serif;
        }

        .formButton {
            font-family: "Roboto", sans-serif;
            border-radius: 5px !important;
            background: #0b1e60 !important;
            border-color: #0b1e60 !important;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        }
    }

    .backLogin {
        color: #1890ff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        margin-top: 14px;

        .arrowIcon {
            font-size: 12px;
            margin-right: 5px;
        }
    }
    .frownIcon {
        text-align: center;
        font-size: 25px;
        margin-bottom: 20px;

        .frownOutlined {
            border: 1px solid #d6ddeb;
            border-radius: 50px;
            padding: 12px;
            color: #26a4ff;
           
        }
    }
    .associatedAddress {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #525252;
        text-align: center;
    }
}
