.clientBillingRulesCard {
  & > div {
    padding: 16px;
  }

  .cbrHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .editButton {
      color: #bbbbbb;
      font-size: 16px;
      padding: 0;
    }
  }

  .cbrLabel {
    color: #7c8493;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 9px;
  }

  .cbrValue {
    font-weight: 500;
    font-size: 16px;
    color: #25324b;
    // padding-top: 4px;
    margin-bottom: 10px;
  }

  .cbrTags {
    display: flex;
    flex-wrap: wrap;

    & > span {
      white-space: normal;
      text-align: center;
    }

    & > span {
      background: #f3f3f3;
      border-radius: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #25324b;
      padding: 4px 12px 4px 12px;
      margin-bottom: 10px;
      border: none;
    }
  }

  .clientBillings {
    margin: 0 !important;
  }

  .currentBillingRule {
    margin: 18px 0 28px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .cbrTitle {
      min-width: 160px;
      margin-right: 2px;

      & > h5 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600;
        font-family: "AvenirLTProBook";
        color: #000000;
        text-transform: capitalize;
      }

      & > div {
        color: #5a5a5a;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 0;

        @media screen and (max-width: 6027px) {
          margin-bottom: 10px;
        }

        & > i {
          margin-right: 7px;
          font-size: 13px;
          color: #1b3799;
        }
      }
    }

    .dividerLine {
      flex: 1;
      margin: 0 20px;
      min-width: inherit;
      color: #dddddd;

      @media screen and (max-width: 786px) {
        display: none;
      }
    }

    & > button {
      min-width: 96px;
      color: #161616;
      box-shadow: none;
      margin-left: 10px;
    }
  }

  .retentionFee {
    font-weight: 500;
    color: #7c8493;
    margin-bottom: 10px;
    margin-top: 12px;
  }
}

.addButtonModule {
  & > :nth-child(2) {
    & > :nth-child(2) {
      border-top: none;
      padding-top: 4px;
      padding-bottom: 28px;
    }
  }

  .fieldSet {
    padding: 26px 20px 8px 20px;
    border-width: 1px;
    border-style: dashed;
    border-color: #278dc0;
    border-radius: 5px;

    .fieldTitle {
      border-width: 1px;
      border-style: dashed;
      border-color: #278dc0;
      color: white;
      width: 128px;
      height: 33px;
      // padding: 4px;
      background-color: #278dc0;
      border-radius: 5px;
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      margin-bottom: 0;
    }

    .datePicker {
      max-width: 477px;
      width: 100%;

      & > div {
        & > span {
          color: rgba(238, 238, 238, 1);
        }
      }
    }

    .brRetentionFee {
      margin-bottom: 25px !important;
    }

    .brFormItems {
      margin-bottom: 20px;

      .brSelectField {
        min-width: 63px;
        width: 100px;
      }

      .brSelectOptions {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    .formTitle {
      border: 1px solid #d9d9d9;
      height: 32px;
      color: #d9d9d9;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      & > div {
        padding: 4px;

        label {
          color: #a8a8a8;
        }
      }

      & > :nth-child(2) {
        display: none;
      }
    }
  }

  .cancelBillingRules {
    border-radius: 5px;
  }

  .saveBillingRules {
    border-radius: 5px;
    background: #0b1e60;
    color: white;
  }
}

.collapseCard {
  & > :first-child {
    & > div {
      & > div {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

.resourceFeeWrapper {
  margin-bottom: 25px;
  //   background-color: red;
  & > div:first-child {
    font-size: 1.25rem;
    color: #0b1e60;
    font-weight: 700;
    background: #f9f9f9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  & > div:last-child {
    background: rgba(0, 0, 0, 0.05);
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding: 15px;
    }
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
  }
}

// .BillingTable {
//   table {
//     border: 1px solid #dddddd;
//     thead {
//       th {
//         padding: 12px 10px;
//         border-color: #dddddd;
//         font-weight: 700;
//         font-size: 16px;
//         color: rgba(0, 0, 0, 0.85);
//         background: #f1f4ff;
//         span {
//           border-left: 1px solid rgba(0, 0, 0, 0.06);
//           margin-left: 10px;
//           padding: 4px 0px 4px 10px;
//         }
//       }
//       th + th {
//         color: #7c8493;
//         font-weight: 600;
//         font-size: 14px;
//       }
//     }
//     td {
//       padding: 12px 19px;
//       font-weight: 400;
//       font-size: 14px;
//       color: #7c8493;
//     }
//   }

//   ul {
//     display: none;
//   }
// }
// .currentBillingTable {
//   table {
//     border: 1px solid #dddddd;
//     tbody {
//       tr {
//         &:nth-child(even) {
//           background: #fafafa;
//         }
//         &:nth-child(4n + 1) {
//           td {
//             font-weight: 700;
//             font-size: 16px;
//             padding-left: 10px;
//           }
//         }
//       }
//     }
//   }
// }
// .currentBillingRulesTable {
//   table {
//     border: 1px solid #dddddd;
//     tbody {
//       tr {
//         &:nth-child(odd) {
//           td {
//             font-weight: 700;
//             font-size: 16px;
//             color: #7c8493;
//             &:last-child {
//               font-weight: 600;
//               font-size: 14px;
//               color: #7c8493;
//             }
//           }
//         }
//         &:nth-child(even) {
//           background: #fafafa;
//           td {
//             &:first-child {
//               font-weight: 400;
//               font-size: 14px;
//               color: #7c8493;
//             }
//             &:last-child {
//               font-weight: 600;
//               font-size: 14px;
//               color: #7c8493;
//             }
//           }
//         }
//       }
//     }
//   }
// }
.timeBasedTable {
  tbody {
    tr {
      &:nth-child(odd) {
        background: #fafafa;
      }
    }
  }
}
