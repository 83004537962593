.billing-collapse-wrap {
  background-color: transparent;
  border: none;
  .ant-spin-nested-loading + .ant-spin-nested-loading {
    margin-top: 10px;
  }
  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      background-color: #1b3799;
      .ant-collapse-header-text {
        color: white;
      }
      .ant-collapse-expand-icon {
        i {
          color: white;
        }
      }
    }
  }
  .ant-collapse-header {
    padding: 1rem 1.75rem !important;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 4px !important;
    .ant-collapse-header-text {
      font-weight: 500;
      display: flex;
      align-self: center;
      color: #000000;
    }
    .ant-collapse-expand-icon {
      i {
        font-size: 10px !important;
        color: #000000;
        padding-right: 10px;
      }
    }
  }
  .ant-collapse-content {
    background-color: transparent;
    border-top: none;
    .ant-collapse-content-box {
      padding: 0;
    }
  }
  .collapse-card {
    border-radius: 4px;
  }
}
.billing-collapse-wrap + .billing-collapse-wrap {
  margin-top: 1rem;
}
