.candidateDetailsWrapper {
  display: flex;
  gap: 24px;
  width: 100%;

  // @media screen and (min-width: 768px) and (max-width: 991px){
  //     max-height: calc(100vh - 245px);
  // }
  // @media screen and (min-width: 1220px) {
  //     height: calc(100vh - 190px);
  // }
  @media screen and (max-width: 1219px) {
    flex-direction: column;
  }

  .candidateProfileCard,
  .candidateInfoCard {
    background: #ffffff;
    border: 1px solid #d6ddeb;
    border-radius: 0;
  }

  .candidateProfileCardWrapper {
    @media screen and (min-width: 1220px) {
      width: 352px;
    }

    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }

  .candidateProfileCard {
    width: 100%;
    height: 100%;

    .profileImageAndNameWrapper {
      display: flex;
      align-items: center;
      gap: 21px;

      .profileImageWrapper {
        position: relative;
        width: 96px;

        &::after {
          content: "";
          padding-top: 100%;
          display: block;
        }

        .profileImage {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: cover;
        }
      }

      .profileName {
        width: calc(100% - 117px);
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #25324b;
        margin-bottom: 0;
      }
    }

    .candidateContact {
      .contactTitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: #25324b;
      }

      .iconAndContactWrapper {
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        .cpIconWrapper {
          width: 24px;
          text-align: center;

          .cpIconStyle {
            color: #7c8493;
            line-height: 160%;
            font-size: 16px;
          }
        }

        .cpContactWrapper {
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);

          .cpLabelStyle,
          .cpTextStyle {
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
          }

          .cpLabelStyle {
            color: #7c8493;
          }

          .cpTextStyle {
            color: #25324b;
          }
        }
      }
    }
  }

  .candidateInfoCard {
    @media screen and (min-width: 1220px) {
      width: calc(100% - 376px);
    }

    @media screen and (max-width: 1219px) {
      width: 100%;
    }

    .cicTabsContent {
      padding: 0.5rem 1.5rem 1.5rem;

      @media screen and (min-width: 768px) {
        overflow-x: hidden;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-height: calc(100vh - 338px);
      }

      @media screen and (min-width: 992px) {
        max-height: 370px;
        overflow: auto;
      }

      &.userInfoCard {
        max-height: initial !important;
      }

      .personalInfoHeading {
        font-weight: 600;
        font-size: 18px;
        line-height: 160%;
        color: #25324b;
      }

      .icLabelStyle,
      .icTextStyle {
        font-size: 16px;
        line-height: 160%;
      }

      .icLabelStyle {
        font-weight: 400;
        color: #7c8493;
        display: block;
      }

      .icTextStyle {
        font-weight: 600;
        color: #25324b;

        .textNormal {
          color: #7c8493;
        }
      }

      .jobsCardsWrapper {
        display: flex;
        gap: 16px;
        align-items: center;
        border-radius: 0;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        .jobCardContentWrapper {
          display: flex;
          gap: 1rem;

          @media screen and (min-width: 1201px) {
            align-items: center;
          }

          @media screen and (min-width: 768px) and (max-width: 1200px) {
            flex-direction: column;
          }

          @media screen and (min-width: 576px) and (max-width: 767px) {
            align-items: center;
          }

          @media screen and (max-width: 575px) {
            flex-direction: column;
          }

          .jobWrapper {
            // @media screen and (min-width: 1201px) {
            //     width: calc(100% - 186px);
            // }

            @media screen and (min-width: 768px) and (max-width: 1200px) {
              width: 100%;
            }

            @media screen and (min-width: 576px) and (max-width: 767px) {
              width: calc(100% - 186px);
            }

            @media screen and (max-width: 575px) {
              width: 100%;
            }

            .jobTitle {
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              color: #25324b;
            }

            .locationsList {
              font-weight: 400;
              font-size: 16px;
              line-height: 160%;
              color: #7c8493;
            }

            .tagesListWrapper {
              display: flex;
              gap: 10px;
              margin-top: 9px;
              flex-wrap: wrap;

              .jobTimeDivider {
                height: 37px;
                border-color: #d6ddeb;
                margin: 0;
              }

              .tagesStyle {
                border-radius: 80px;
                padding: 5px 10px;
                font-weight: 600;
                font-size: 14px;
                line-height: 160%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 34px;

                &.fullTime {
                  color: #56cdad;
                  background: rgba(86, 205, 173, 0.1);
                  border: 1px solid transparent;
                }

                &.marketing {
                  border: 1px solid #ffb836;
                  color: #ffb836;
                }

                &.design {
                  border: 1px solid #4640de;
                  color: #4640de;
                }
              }
            }
          }

          .progressBarWrapper {
            width: 170px;

            .appliedNumber {
              font-weight: 600;
              font-size: 14px;
              line-height: 160%;
              color: #25324b;

              .textNormal {
                color: #7c8493;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }

  .candidateViewActivity {
    & > div {
      overflow: auto;
      overflow-x: hidden;
    }
  }
}

.cdDividerStyle {
  border-color: #d6ddeb;
}

button.blacklistBtn {
  border-color: #ccccf5;
}

.blacklistAlert {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 20px;
  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
  }
}
