.jobBillingCardComponent {
    border: none;
    margin-top: 15px;
}

h3.cardTitle {
    padding: 30px 30px 30px 0px;
    margin-bottom: 0;
    font-size: 16px;
    font-family: "AvenirLTProBook";
    font-weight: 600;
}

.cardWrapper {
    &>:first-child {
        border-bottom: none;
        padding-top: 30px;
        padding-bottom: 0;

        &>div {
            &>div {
                font-size: 16px;
                font-weight: 700;
                padding: 0;
            }
        }

    }

    .jobBillingUnitForm+.jobBillingUnitForm {
        &>:nth-child(2) {
            margin-top: 50px;
        }

    }


    .jobBillingUnitForm+.jobBillingUnitForm {
        margin-top: 50px;
        border-top: 1px dashed #D9D9D9;
        position: relative;
    }

    .jobBillingUnitRemoveBtn {
        position: absolute;
        right: 25px;
        top: 22px;
        z-index: 1;
    }



    +.cardWrapper {
        margin-top: 20px;

    }

    .cardForm {
        label {
            border-radius: 5px;
        }

    }

    .fieldSet {
        margin-top: 20px;
        padding: 0px 20px 0px 20px;
        border-width: 1px;
        border-style: dashed;
        border-color: #278DC0;
        border-radius: 5px;

        .fieldTitle {
            border-width: 1px;
            border-style: dashed;
            border-color: #278DC0;
            color: white;
            width: 130px;
            height: 33px;
            background-color: #278DC0;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            align-items: center;
        }

        .brFormItems {
            margin-bottom: 20px;

            .brSelectField {
                min-width: 63px;
                width: 100px;
            }

            .brSelectOptions {
                color: rgba(0, 0, 0, 0.25);
            }
        }

        .datePicker {
            max-width: 477px;
            width: 100%;

            &>div {
                &>span {
                    color: rgba(238, 238, 238, 1);
                }
            }
        }
    }

    .formTitle {
        border: 1px solid #D9D9D9;
        height: 32px;
        color: #D9D9D9;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        &>div {
            padding: 4px;

            label {
                color: #A8A8A8;
                display: flex;
                justify-content: center;
            }
            &>:nth-child(2) {
                display: none;
    
            }
        }

        
    }
}

.deletIcon {
    color: #8C8C8C;
}

.jobBillingSelect {
    width: 100%;
}

.moreTierBtn {
    display: flex;
    justify-content: flex-end;

    .cardButton {
        margin-top: 30px;
        border-radius: 5px;
        text-align: right;
    }
}

.headerModal {
    &>div {
        max-width: 416px;
        width: 100%;
        height: 168px;
    }

    .modalTitle {
        font-weight: 700 !important;
        font-family: 'Roboto', sans-serif;
    }

    .someContent {
        font-size: 16px;
        font-weight: 400;
        margin-left: 42px;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 24px;
    }

}

.clientSaveButton {
    background-color: #0B1E60;
    box-shadow: none;
    border: none;
    margin-top: 30px;

    @media screen and (min-width:576px) {
        margin-top: -16px;
    }

    @media screen and (min-width:576px) {
        position: absolute;
        top: -30px;
        right: 0;
    }

    @media screen and (max-width:575px) {
        width: 100%;

    }
}