.profileCardWrapper {
    background: #ffffff;
    border-radius: 5px;
    .pInfoWrapper {
        display: flex;
        gap: 24px;
        margin-top: 18px;
        &.profilePhotoRow {
            margin-top: 48px;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
        .headingsWrapper {
            @media screen and (min-width: 768px) {
                width: 260px;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
        .pFromWrapper {
            @media screen and (min-width: 768px) {
                width: calc(100% - 284px);
            }
            @media screen and (max-width: 767px) {
                width: 100%;
            } 
        }
    }

    .biHeading {
        font-weight: 800;
        font-size: 16px;
        line-height: 160%;
        color: #202430;
        margin-bottom: 4px;
        // &.pFromHeading {
        //     margin-top: 83px;
        // }
    }
    .biText {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #515b6f;
        margin-bottom: 0;
    }
    .changePasswordBtn {
        border: 1px solid #4640de;
        border-radius: 5px;
        width: 100%;
    }
    .saveProfileBtn {
        background-color: #4640DE;
        border-color: #4640DE;
        border-radius: 5px; 
        font-weight: 800;
        // font-size: 16px;
        line-height: 160%;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 36px;
        // height: 50px;
        min-width: 140px;
    }
    // .spBtnWrapper {
    //     display: flex;
    //     justify-content: flex-end;
    // }
}
