.sitePageHeader {
  @media print {
    display: none;
  }

  border: 1px solid rgb(235, 237, 240);
  background-color: #fff;

  .ant-page-header-heading {
    @media screen and (min-width: 586px) {
      align-items: center;
    }

    @media screen and (max-width: 585px) {
      position: relative;
      flex-direction: column;
    }
  }

  .ant-space {
    @media screen and (max-width: 585px) {
      display: block;
    }
  }

  .ant-page-header-heading-title {
    font-size: 24px;
  }

  .headerDatePicker {
    padding: 4px 20px;
    margin-right: 22px;

    .ant-picker-input {
      ::placeholder {
        color: #25324b;
        font-size: 16px;
      }
    }

    .ant-picker-suffix {
      .icon-calender-date {
        margin-left: 26px;
        color: #4640de;
        font-size: 16px;
      }
    }
  }

  .headerPragraph {
    margin-bottom: 0;
    font-size: 14px;
  }

  .printDashboard {
    display: flex;
    align-items: center;

    @media screen and (max-width: 585px) {
      position: absolute;
      right: 0;
      top: 4px;
    }

    .printIcon {
      padding-right: 10px;
      padding-left: 7px;
    }
  }
}

.historicalPerformanceCard {
  margin-bottom: 20px;

  @media print {
    display: none;
  }

  .ant-card-body {
    padding: 16px;

    @media screen and (max-width: 399px) {
      padding: 16px 16px 16px 16px;
    }
  }

  .historicalPerformance {
    .hpTitle {
      font-size: 18px;
      font-weight: 700;
      color: #161616;
      white-space: nowrap;
      margin-bottom: 16px;

      @media screen and (max-width: 1300px) {
        margin-bottom: 16px;
      }
    }

    .formSubmitBtn {
      margin-top: 20px;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
    }

    .selectMenu {
      .formItems {
        .ant-select-arrow {
          font-size: 10px;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.recruitmentPipelineCard {
  margin-bottom: 24px;

  @media print {
    border: 0;
    page-break-after: always;
  }

  .rowDivider {
    border-color: #e5e5e5;
    margin-top: 0;
    margin-bottom: 13px;

    @media print {
      margin-bottom: 20px;
    }
  }

  .activeCandidate {
    color: #7d7d7d;
    font-weight: 700;
    font-size: 14px;

    @media print {
      margin-bottom: 20px;
    }
  }

  .ant-card-head {
    border-bottom: 0;
    padding: 0 16px;

    @media print {
      padding: 0;
    }
  }

  .ant-card-head-title {
    font-weight: 700;
    font-size: 14px;
    color: #7d7d7d;
    padding-bottom: 0;

    @media print {
      margin-bottom: 15px;
      padding-top: 0;
    }
  }

  .recruitmentPipelineRow {
    margin-bottom: 13px;

    @media print {
      & > div {
        border-right: 1px solid;
        border-right-color: #e5e5e5;
      }

      & > :nth-child(4) {
        border-right: none;
      }

      & > :last-child {
        border: none;
      }
    }

    & > :last-child {
      .recruitmentPipelineSec {
        &::before {
          background-color: transparent;
        }
      }
    }

    & > :not(:last-child) {
      &::before {
        content: "";
        background-color: transparent;
      }
    }
  }

  .ant-card-body {
    padding: 0px 16px 16px 16px;

    @media print {
      padding: 0;
    }
  }

  .pipelineWrap {
    position: relative;
  }

  .recruitmentPipelineSec {
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 27px;
      right: 0;
      top: 8px;
      background-color: #e5e5e5;
    }

    h5 {
      color: #6d6e71;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
    }

    span {
      color: #278dc0;
      font-size: 14px;
      font-weight: 700;

      @media print {
        color: #4d72be;
      }
    }
  }

  .openingCol {
    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &::before {
      @media screen and (min-width: 320px) and (max-width: 575px) {
        background-color: transparent;
      }
    }
  }

  .withdrewCol {
    &::before {
      @media screen and (min-width: 576px) and (max-width: 767px) {
        background-color: transparent;
      }
    }
  }

  .candidateCol {
    &::before {
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        background-color: transparent;
      }
    }
  }

  .recruitmentTimeline {
    .recruitmentTimelineHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;

      h5 {
        color: #7d7d7d;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }

      a {
        font-size: 12px;
        color: #7d7d7d;
      }
    }
  }
}

.currentOpeningCard {
  height: 100%;

  h4 {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .currentOpeningContent {
    text-align: center;

    & > span {
      color: #278dc0;
      font-size: 14px;
      font-weight: 500;
    }

    & > div {
      color: #6d6e71;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.graphHeaderTabs {
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #fff !important;
      background-color: #0b1e60 !important;
      border-color: #0b1e60 !important;
      z-index: 99;
      border-radius: 4px !important;
      font-weight: 500 !important;
    }
  }

  .ant-tabs-nav-list {
    @media screen and (max-width: 585px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    @media screen and (max-width: 585px) {
      margin-left: 0 !important;
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav {
    margin: 0;

    &::before {
      border-bottom: 0 !important;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }

    .ant-tabs-nav-wrap {
      @media screen and (max-width: 585px) {
        display: block;
      }

      .ant-tabs-nav-list {
        & > :nth-child(2) {
          @media screen and (min-width: 575px) {
            margin-left: -6px;
          }
        }

        .ant-tabs-tab {
          padding: 0;
          border: none;
          background-color: transparent;

          @media screen and (max-width: 585px) {
            width: 100%;
          }

          .ant-tabs-tab-btn {
            padding: 4px 19px;
            background: white;
            border-radius: 4px;
            color: #5a607f;
            border: 1px solid #d7dbec;
            font-size: 12px;
            font-weight: 400;

            @media screen and (max-width: 585px) {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .ant-tabs-nav-operations {
    button {
      padding: 0 !important;
    }
  }

  .cardHeader {
    height: 100%;

    @media print {
      border: 0;
    }

    .ant-card-body {
      padding: 16px;
    }

    .CardTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      position: relative;
      padding-right: 20px;

      & > h4 {
        display: flex;
        gap: 10px;
        margin-bottom: 0;

        @media print {
          font-size: 12px;
        }

        & > button {
          padding: 0;
          display: flex;
          align-items: center;

          @media print {
            display: none;
          }

          .icon-info-circle {
            color: #b7b7b7;
            font-size: 16px;

            &:hover {
              color: #faad14;
            }
          }

          .ant-tooltip-inner {
            background-color: #faad14;
          }
        }
      }

      .icon-arrows {
        font-size: 16px;
        color: #000000;
        position: absolute;
        top: 8px;
        right: 0;
        cursor: move;

        @media print {
          display: none;
        }
      }
    }

    .ant-card-head {
      border-bottom: none;
    }

    .ant-card-head-wrapper {
      .ant-card-head-title {
        font-size: 16px;
        font-weight: 500;
        color: #161616;
      }
    }
  }
}

.graphHeaderTitle {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .graphHeading {
    position: relative;

    h4 {
      padding-right: 15px;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      color: #161616;

      @media screen and (max-width: 400px) {
        padding-right: 8px;
      }
    }

    & > ::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 20px;
      right: 0;
      top: 4px;
      background-color: #c4c4c4;

      @media screen and (max-width: 390px) {
        right: 14px;
        top: 19px;
      }
    }
  }

  .ant-dropdown-trigger {
    padding: 0 14px;
    color: #4640de;
    font-weight: 750;
    font-size: 14px;
    cursor: pointer;

    @media print {
      display: none;
    }
  }

  .icon-Vector {
    font-size: 8px;
    color: #4640de;
    padding-left: 12px;
  }
}

.requisitionTitle {
  font-size: 18px !important;
  font-weight: 700 !important;
  font-style: normal;
  font-family: "AvenirLTProMedium";
  margin-top: 20px;
}

.requisitionCard {
  margin-top: 16px;
  margin-bottom: 20px;
  border-radius: 5px;

  @media print {
    border: none;
  }

  .ant-card-body {
    padding: 0 !important;
  }

  .cardHeaderRequisition {
    @media print {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 16px 20px;

    @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .headerTitle {
      font-size: 18px !important;
      font-weight: 700;
      font-style: normal;
      font-family: "AvenirLTProMedium";
      margin-bottom: 0px;

      @media print {
        font-size: 12px !important;
      }
    }

    .headerID {
      font-weight: 400;
      font-size: 18px;
      font-family: "AvenirLTProBook";
      margin-bottom: 0px;
      cursor: pointer;
      @media print {
        font-size: 12px !important;
      }
    }

    .containHeader {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;

      @media print {
        align-items: baseline;
      }
    }
  }

  .cardInnerDetails {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;

    @media print {
      gap: 10px !important;
    }

    @media screen and (max-width: 1310px) {
      gap: 20px;
    }

    .cardInnerDesc {
      display: flex;
      gap: 10px;
      margin-bottom: 0px;
      align-items: center;
      flex-wrap: wrap;

      @media print {
        align-items: baseline;
        gap: 5px;
      }
      @media screen and (max-width: 457px) {
        width: 100%;
      }

      .cardInnerIdTitle {
        font-family: "AvenirLTProMedium";
        font-weight: 600;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 0px;

        @media print {
          font-size: 12px !important;
        }
      }

      .cardInnerIdDesc {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0px;

        @media print {
          font-size: 12px !important;
        }
      }
    }
  }

  .cardGrid {
    background-color: #f4f7fe;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    border: 1px solid #e5e5e5;

    .gridTitle {
      font-size: 20px;
      font-family: "AvenirLtProBook";
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0px;

      @media print {
        font-size: 12px !important;
      }
    }

    .gridParagraph {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0px;
      white-space: nowrap;

      @media print {
        font-size: 12px !important;
      }
    }

    .rowGrid {
      display: flex;

      @media print {
        flex-wrap: wrap;
        gap: 15px;
      }

      @media screen and (max-width: 1530px) {
        gap: 20px;
        flex-wrap: wrap;
      }

      .gridCol {
        @media print {
          width: calc(33.33% - 15px);
        }

        @media screen and (min-width: 1200px) {
          width: calc(16.16% - 21px);
        }

        @media screen and (min-width: 576px) and (max-width: 1199px) {
          width: calc(33.33% - 21px);
        }

        @media screen and (min-width: 398px) and (max-width: 575px) {
          width: calc(50% - 21px);
        }

        @media screen and (max-width: 397px) {
          width: 100%;
        }
      }
    }

    .ant-divider-vertical {
      height: 40px;
      margin-top: 5px;
      margin-left: 20px;
      margin-right: 20px;
      color: #e5e5e5;

      @media print {
        display: none;
      }

      @media screen and (max-width: 1530px) {
        display: none;
      }
    }
  }
}
