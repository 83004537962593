.cardContent {
  margin-top: 18px;

  @media screen and (min-width:576px) {
    display: flex;
    margin-bottom: 20px;
    margin-right: 30px;
  }

  .cardContentItems {
    h5 {
      font-size: 14px;
      font-weight: 600;
      font-family: "AvenirLTProBook";
    }
    .ant-descriptions-item {
      padding-bottom: 12px;
      &:nth-child(even){
        padding-left: 10px;
      }

      .ant-descriptions-item-content {
        font-size: 14px;
        color: #6D6E71 !important;
        font-weight: 500;
        font-family: "AvenirLTProBook";
        gap: 10px;
        flex-wrap: wrap;
        
        span{
          margin: 0;
        }

      }
    }
  }

  .cardContentSecIcon {
    display: flex;
    gap: 16px;

    @media screen and (min-width:575px) {
      order: 2;
      margin-left: 10px;
    }


    .lockIcon {
      margin-top: 12px;
    }

    .lockIconText {

      h4 {
        font-size: 16px;
        font-weight: 500;
        font-family: "AvenirLTProBook";
        margin: 0;
        color: #515B6F;
        @media screen and (min-width:576px) {
          min-width: 115px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        font-family: "AvenirLTProBook";
        color: #25324B;
      }
    }


  }

  .cardContentIcon {
    border: 1px solid black;
    border-radius: 50%;
    color: #1890ff;
    border-color: #d9d9d9;
    padding: 12px;
    font-size: 18px;

  }
}

.cardContent2ndCard {
  display: flex;
  gap: 20px;

  @media screen and (min-width:768px) {
    position: absolute;
    content: "";
    bottom: 25px;
  }

  .ant-typography {
    margin-bottom: 0;
  }

  .sec-para {
    color: red;
    font-size: 14px;
    font-weight: 400;
  }

  .btn-switch {
    width: 30px;
  }

  .ant-switch-checked {
    background-color: #43A047;
  }
}

.clientCard {
  height: 100%;
  padding: 0 16px;

  .header-divider {
    margin: 0 0 18px 0
  }

  .ant-card-head {
    padding: 0;

    .ant-card-extra {
      padding: 0;
    }
  }

  .ant-card-body {
    padding: 0 !important;
  }

  .ant-card-head-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "AvenirLTProBook";
  }

  // .ant-card-body {
  //   padding: 0 16px 16px 16px;
  // }
}

.billingCard {
  padding: 0 16px;
  height: 100%;
  position: relative;


  .ant-card-head {
    padding: 0 !important;
  }

  .ant-card-body {
    padding: 0;

    @media screen and (max-width:768px) {
      margin-bottom: 16px !important;
    }
  }

  .header-divider {
    margin: 0 0 18px 0
  }

  .billingInformationContent {
    margin-top: 18px;

    h4 {
      font-size: 16px;
      font-weight: 600;
      font-family: "AvenirLTProBook";
      color: rgba(0, 0, 0, 0.85);
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-family: "AvenirLTProBook";
      color: rgba(0, 0, 0, 0.45);
    }

  }

  .ant-card-extra {
    padding: 13px 0;

    a {
      color: #1890FF;
    }

    .icon-edit1 {
      margin-right: 5px;
      margin-left: 10px;
    }
  }

  .ant-card-head-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "AvenirLTProBook";
  }
}

.detailsBillingUnit {
  margin-top: 24px;
  font-family: "AvenirLTProMedium";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px !important;
}



.ant-form-item-control {
  padding-left: 0;
  padding-right: 0;
}


.overviewAddNewButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapseContainer {
  .ant-collapse-header {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}