.job-header-wrap {
  .job-options-dropdown {
    background-color: #fff4f4;
    border-radius: 50px;
    padding-left: 14px;
    padding-right: 14px;
    color: #262626;
    font-weight: 600;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    //   .ant-select-selector {
    //     border: none !important;
    //     box-shadow: none !important;
    //     &:hover,
    //     :focus-visible {
    //       border: none;
    //     }
    //     .ant-select-selection-item {
    //       background-color: #fff4f4;
    //       border-radius: 50px;
    //       color: #262626;
    //       font-weight: 600;
    //       font-size: 12px;
    //       padding-left: 14px;
    //       padding-right: 14px;
    //       display: flex;
    //       align-items: center;
    //     }
    //   }
    i {
      padding-left: 10px;
      color: #000000;
    }
    .icon-Vector {
      font-size: 7px !important;
    }
    .icon-dropdown-arrow {
      font-size: 11px !important;
    }
    .dropdown-dot {
      width: 8px;
      height: 8px;

      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }
    .open-dot {
      background-color: #98fb98;
    }
    .close-dot {
      background-color: #ff0000;
    }
    .cancel-dot {
      background-color: #ff4d4e;
    }
    .hold-dot {
      background-color: #ffc185;
    }
    .filled-dot {
      background-color: #0093c8;
    }
  }
  .ant-page-header-heading-title {
    flex-wrap: wrap;
    .ant-typography {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ant-page-header-heading-left {
    .ant-page-header-back {
      margin-bottom: 0;
    }
  }
  .ant-page-header-heading-extra {
    @media screen and (min-width: 575px) {
      margin-left: auto;
      margin-top: 16px;
    }
  }
  .ant-page-header-heading {
    align-items: flex-start;
  }
  .alert-wrap {
    display: flex;
    gap: 16px;
    align-items: center;
    .ant-alert {
      padding: 8px 10px;
      white-space: break-spaces;
      border-color: #df9100;
      background-color: #fffdf9;
      align-items: flex-start;
      @media screen and (min-width: 575px) {
        width: 270px;
      }
      .ant-alert-icon {
        margin-top: 3px;
      }
      .ant-alert-message {
        color: #262424;
        line-height: 18px;
        a {
          color: #ea9700;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
    .ant-btn {
      width: 34px;
      height: 34px;
      color: #0b1e60;
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon {
        margin-top: 2px;
        font-size: 18px;
      }
    }
  }
}
.ant-dropdown-menu {
  margin-top: 22px;
  min-width: 160px;
  .ant-dropdown-menu-item {
    padding: 10px 14px;
  }
  .dropdown-dot {
    width: 8px;
    height: 8px;

    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
  .open-dot {
    background-color: #98fb98;
  }
  .close-dot {
    background-color: #ff0000;
  }
  .cancel-dot {
    background-color: #ff4d4e;
  }
  .hold-dot {
    background-color: #ffc185;
  }
  .filled-dot {
    background-color: #0093c8;
  }
}
.subtitle-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  p {
    font-size: 14px;
    margin-bottom: 0;
    cursor: default;
    line-height: 21px;
  }

  .collapse-title-wrap {
    color: #0b1e60;
    font-size: 14px;
    text-decoration: underline;
    padding-bottom: 1px;
    font-weight: 600;
  }
}
.ant-page-header-content {
  padding-top: 10px;
  padding-left: 44px;
  .collapse-details-wrap {
    span {
      font-weight: 600;
      font-size: 14px;
    }
    h5 {
      font-weight: 400;
      margin-bottom: 0;
    }
    span,
    h5 {
      color: #000000;
      line-height: 18px;
    }
    .tag-title {
      display: block;
    }
    .tag-wrap {
      display: flex;
      flex-wrap: wrap;
      .ant-tag {
        margin-top: 8px;
        padding: 2px 6px;
        border-color: #4d72be;
        background-color: #f4f6ff;
        color: #4d72be;
        font-weight: 400 !important;
      }
    }
  }
}
.settings-drawer-wrap {
  .ant-drawer-content-wrapper {
    max-width: 600px;
    width: 100% !important;
  }
  .ant-drawer-header {
    padding: 24px 24px 22px 24px;
    border-bottom: none;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .ant-drawer-title {
      color: #1b3799;
      font-weight: 700;
      font-size: 20px;
    }
    .anticon-close {
      color: #000000;
    }
  }
  .ant-drawer-body {
    padding-top: 0;
    .opening-card + .opening-card {
      margin-top: 24px;
    }
    .opening-card {
      border-radius: 8px;
      border-bottom: 1px solid #0000000f;
      box-shadow: 0px 0px 8px 1px #00000014;
      .ant-card-body {
        padding: 16px;
      }
      .details-wrap {
        @media screen and (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 8px;
        @media screen and (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }
        h5 {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 1rem;
        }
        h2 {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0;
        }
        .total-opening {
          font-size: 14px;
          margin-top: 0;
        }
        .last-update {
          font-size: 14px;
        }
      }
      .ant-form-item {
        margin-bottom: 12px;
      }
      .add-position-wrap {
        display: flex;
        justify-content: flex-end;
        .ant-btn {
          color: #0b1e60;
          border-color: #0b1e60;
        }
      }
    }
    .history-detail-card {
      border: 1px solid #eaeaea;
      border-radius: 8px;
      padding: 14px 0 14px 0;
      .history-detail {
        display: flex;
        padding-bottom: 16px;
        @media screen and (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }

        h5 {
          color: rgba(51, 51, 51, 0.75);
          font-weight: 400 !important;
        }
        .reason {
          span {
            font-weight: 600;
            color: rgba(38, 38, 38, 0.85);
          }
        }
        .ant-typography {
          font-weight: 600;
        }
      }

      .ant-divider {
        margin-top: 0 !important;
        margin-bottom: 12px;
      }
    }
  }
}
.statistics-wrap {
  .ant-card-head-title {
    color: rgba(11, 30, 96, 1);
  }
  .slick-slide {
    max-width: 148px;
  }
  .statistic-data {
    border: 1px solid rgba(217, 217, 217, 0.4);
    padding: 8px;
    border-radius: 4px;
    // max-width: 148px;
    h5 {
      font-size: 12px !important;
      color: rgba(0, 0, 0, 0.85) !important;
      line-height: 14px;
      font-weight: 600 !important;
    }
  }
  .statistics-count {
    line-height: 19px;
  }
  .slick-slide + .slick-slide::after {
    display: none;
  }
  .slick-track .slick-slide:not(:first-child) {
    margin-left: 16px;
  }
}
.status-card-wrap {
  h5 {
    color: #0b1e60;
    margin-bottom: 6px;
  }
  .total-number {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #262626;
  }
  .box-active {
    .status-inner-boxes {
      border-color: #0b1e60;
      background-color: #f6f8ff !important;
      h5 {
        font-weight: 600;
      }
      h5,
      .hover-underlined {
        color: #0b1e60;
      }
      .hover-underlined {
        font-weight: 600 !important;
      }
    }
  }
  .status-inner-boxes {
    border: 1px solid #d9d9d966;
    background-color: transparent !important;
    display: flex;
    border-radius: 6px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 10px 10px;
    h5 {
      font-size: 14px !important;
      color: #252626;
      display: flex;
      align-items: center;
      line-height: 18px;
      i {
        padding-left: 12px;
      }
      .icon-dropdown-arrow {
        color: #bfbfbf;
        font-size: 10px;
      }
      .icon-Vector {
        color: #0b1e60;
        font-size: 6.5px !important;
        margin-top: 2px;
      }
    }
    .hover-underlined {
      color: #252626;
      line-height: 18px;
    }
  }
}
.count-breakdown-card {
  border: 1px solid #c1cfff;
  background-color: #f9faff;
  border-radius: 6px;
  margin-top: 12px;
  .ant-card-head-wrapper {
    padding-top: 16px;
    .ant-card-head-title {
      font-size: 14px !important;
      font-weight: 600 !important;
      padding-top: 0;
    }
    .ant-card-extra {
      h5 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
  .ant-card-body {
    padding-top: 0 !important;
    .count-breakdown-open {
      &.active {
        background-color: #0b1e60;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        h5 {
          color: white;
        }
        .hover-underlined {
          color: white !important;
        }
      }
    }
    .slick-slide {
      max-width: 300px !important;
      width: 100% !important;
      .slick-track {
        margin: 0 !important;
      }
    }
    .count-breakdown-open {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #c1cfff66;
      border-radius: 4px;
      gap: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px;
      flex-direction: row-reverse;
      // @media screen and (min-width: 576px) {
      //   min-width: 300px;
      // }

      h5 {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 16px;
      }
      .hover-underlined {
        margin-bottom: 0;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}
.card-bottom-wrapper {
  .title-card-bottom {
    font-size: 20px;
    font-weight: 700;
    color: #0b1e60;
    line-height: 24px;
  }
  .ant-input-group-wrapper {
    @media screen and (min-width: 992px) {
      width: 264px;
    }
  }
  .right-side-info {
    gap: 12px !important;
  }
  .job-search-wrap {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    .anticon-search {
      @media screen and (max-width: 575px) {
        padding: 6px;
      }
    }
  }
  .btn-add-candidate {
    border-color: #0b1e60;
    color: #0b1e60;
    &:hover {
      color: #2e4cba;
      border-color: #2e4cba;
    }
  }
}
