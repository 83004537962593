.wrapPageHeader {
  @media print {
    display: none;
  }
  .invoicePageHeader {
    border-bottom: 1px solid #eaeaea;

    & > div {
      font-family: "AvenirLTProMedium";

      & > :nth-child(2) {
        & > span {
          & > span {
            color: #000000;
          }
        }
      }
    }

    & > :nth-child(2) {
      margin-top: 28px;

      & > div {
        & > div {
          margin-right: 18px;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
        }

        & > span {
          font-weight: 700;
        }
      }
    }

    .sendButton {
      background-color: #0b1e60;
      box-shadow: none;
      border: none;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 5px;

      & > span {
        font-weight: 700;
      }
    }
  }
}

.headerModal {
  & > div {
    border-radius: 2px;
  }

  .checkCircle {
    color: #52c41a;
    font-size: 22px;
    margin-right: 16px;
  }

  .modalTitle {
    font-weight: 700 !important;
    font-family: "AvenirLTProMedium";
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .modalButton {
    display: flex;
    justify-content: flex-end;

    .doneButton {
      background: #0b1e60;
      color: white;
      width: 66px;
      border: none;
      border-radius: 5px;
    }
  }
}

.topInfoCard {
  @media print {
    display: none;
  }

  margin: 24px 0;

  .generateInvoiceCard {
    width: 100%;
    border-radius: 5px;

    & > div {
      // @media screen and (max-width:767px) {

      //     display: flex;
      //     flex-direction: column;
      // }

      // @media screen and (min-width:768px) {
      //     display: flex;
      // }

      .topCardDetail {
        // max-width: 842px;
        // width: 100%;
        display: flex;
        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
        }

        .cardBox {
          padding: 24px 24px 24px 24px;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          @media screen and (min-width: 768px) {
            width: 75%;
          }

          & > ul {
            margin-bottom: 0;
            padding-left: 0;

            & > li {
              list-style-type: none;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 8px;
              font-family: "AvenirLTProBook";

              .cardTag {
                font-size: 12px;
                color: #f5222d;
                background-color: #fff1f0;
                display: flex;
                align-items: center;
                border-radius: 2px;
                font-family: "AvenirLTProBook";
              }
            }
          }

          .cardIdDate {
            font-weight: 600;
            font-size: 14px;
            font-family: "AvenirLTProBook";
          }

          .overdueDays {
            font-weight: 600;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.25);
            margin-top: 8px;
            margin-bottom: 0 !important;
            font-family: "AvenirLTProBook";
          }
        }

        .datePicker {
          // max-width: 842px;
          width: 100%;
          margin-top: 18px;
          border-radius: 5px;
        }
      }

      .cardRightSide {
        & > ul {
          list-style-type: none;
          padding-left: 20px;

          @media screen and (max-width: 767px) {
            padding-left: 0;
          }

          @media screen and (min-width: 768px) {
            padding-top: 7px;
            margin-bottom: 0;
            width: 300px;
          }

          & > div {
            .ant-typography {
              margin-bottom: 0 !important;
            }
          }

          .clientBalance {
            margin-bottom: 7px;
          }

          & > .cardDetails {
            font-size: 14px;
            font-family: "AvenirLTProMedium";
            margin-bottom: 0;
            font-weight: 600;
          }

          .cardCintas {
            font-weight: 600;
            margin-bottom: 8px;
            padding-bottom: 2px;
            font-family: "AvenirLTProBook";
          }

          .invoicesDetails {
            font-size: 30px;
            font-weight: 600;
            line-height: 40px;
            font-family: "AvenirLTProBook";
          }

          .invoicesPrint {
            display: flex;
            justify-content: flex-start;
            gap: 12px;

            & > :nth-child(2) {
              margin-right: 16px;
            }

            .cardDetails {
              color: #1890ff;
              padding: 0;
              font-weight: 700;
              font-size: 14px;
              font-family: "AvenirLTProBook";
            }
          }
        }
      }
    }
  }
}

.bottomInfoCard {
  border-radius: 5px;

  .bottomCardTitle {
    font-size: 16px;
    font-weight: 600;
    color: #231f20;
    margin: 0 8px 25px 8px;
    font-family: "AvenirLTProBook";
    @media print {
      display: none;
    }
  }

  .bottomCardList {
    max-width: 476px;
    width: 100%;
    @media print {
      display: none;
    }
  }

  .bottomCardIcons {
    margin-bottom: 0;

    & > ul {
      align-items: center;
      padding: 0 8px;
      margin-bottom: 32px;

      & > :not(:nth-child(3)) {
        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }

      & > li {
        list-style-type: none;
        font-size: 16px;
        display: flex;
        align-items: center;

        & > div {
          & > p {
            margin-bottom: 0;
          }

          & > h5 {
            margin-bottom: 0;
          }

          .listName {
            color: #515b6f;
          }

          .listDes {
            font-weight: 600;
            color: #25324b;
            font-family: "AvenirLTProBook";
          }
        }

        .invoiceIconDetail {
          padding-left: 16px;
        }
      }

      .invoiceIcon {
        border: 1px solid #d6ddeb;
        border-radius: 50px;
        color: #26a4ff;
        height: 44px;
        min-width: 44px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
