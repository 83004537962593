.jobListHeader {
    &> :first-child {
        &>div {
            @media screen and (max-width:767px) {
                flex-direction: column;
                align-items: flex-start;

                &>:first-child {
                    @media screen and (min-width:576px) and (max-width:767px) {
                        padding-top: 0 !important;

                    }
                }
            }



            &>div {
                @media screen and (min-width:576px) and (max-width:767px) {
                    padding-top: 16px !important;
                    flex-direction: column;
                    width: 100%;
                }



                .jobListHeaderTable {
                    display: flex;
                    gap: 16px;
                    .jobListHeaderSearchBar {
                        margin-right: 0;
                        @media screen and (min-width:768px) {
                            min-width: 264px;
                            width: 100%;
                        }

                    }



                    @media screen and (max-width:575px) {
                        flex-direction: column;
                    }

                    &>:nth-child(2) {
                        .jobListHeaderTableBtn {

                            @media screen and (max-width:575px) {
                                flex-direction: column;
                            }

                            &>.jobListHeaderSelect {
                                min-width: 104px;
                                margin-right: 16px;
                                font-size: 14px;
                                text-align: center;
                                font-family: "AvenirLTProBook";
                                color: #161616;

                                &>span {
                                    &>i {
                                        font-size: 6px;
                                        padding-top: 3px;
                                        color: #D4D4D4;
                                    }
                                }

                                @media screen and (max-width:575px) {
                                    width: 100%;
                                    margin-right: 0;
                                    margin-top: 16px;
                                }

                            }


                            &>.jobListHeaderBtn {
                                margin-right: 16px;

                                @media screen and (max-width:575px) {
                                    width: 100%;
                                    margin-right: 0;
                                    margin-top: 16px;
                                }

                            }

                            &>.jobListHeaderSetting {
                                width: 32px;
                                display: flex;
                                justify-content: space-around;
                                align-items: center;
                                padding-top: 6px;


                                @media screen and (max-width:575px) {
                                    width: 100%;
                                    margin-right: 0;
                                    margin-top: 16px;
                                }

                                .settingIcon {
                                    font-size: 17px;
                                    color: #8C8C8C;

                                }
                            }

                        }


                    }

                }
            }
        }
    }


}