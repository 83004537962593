.invoiceWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .invoiceDetail {
    border: 1px solid #d9dadc;
    border-radius: 5px;
    padding: 16px 30px 16px 30px;
    width: 100%;

    @media screen and (max-width: 400px) {
      padding: 14px;
    }

    .invoiceDate {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      & > h5 {
        margin: 0;
        font-weight: 600;
        color: #25324b;
        font-size: 14px;
        font-family: "AvenirLTProMedium";

        & > span {
          font-weight: 400;
          font-family: "AvenirLTProMedium";
        }
      }
    }
  }

  .invoiceDivider {
    margin: 10px 0;
  }

  .reloadBtn {
    padding: 0;
    color: orange;

    & > i {
      color: orange;
    }
  }
}
