.cardWrapper{
    label{
        font-family: "AvenirLTProBook";
        font-weight: 600;
        font-size: 14px;
    }
    margin-top: 24px;
    border: none;
    h5{
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 700;
    }
    .cardForm{
        &>div{
            // max-width: 532px;
            // width: 100%;
            .selectField{
                &>div{
                border-radius: 5px;
                }
            }
            label{
                font-weight: 400;
                font-size: 14px;
            }
            input{
                border-radius: 5px;
            }
        }
    }
    
}