.react-trello-board{
    background-color: white !important;
.react-trello-lane{
        padding-top: 0;
        padding-left: 0;
        padding-right:0;
        margin-top: 0;
        background-color: #f4f4f4 !important;
        height: 100vh;
        border-radius: 5px;
        margin-right: 20px;
        .trelloCardTitleContainer{
            padding-top: 10px;
            display: flex;
            justify-content: space-around;
            align-items: baseline;
            background-color: white !important;
            width: 250px;
            border-radius: 5px;
            height: 58px;
            
            .cardTitle{
                font-size: 16px;
                font-family: "AvenirLTProBook";
                font-weight: 600;
                color: #25324B;
                &::before{
                content: "";
                display: inline-block;
                border-radius: 50%;
                height: 10px;
                width: 10px;
                margin-right: 15px;

            }
            }
            .cardNumber{
                border: 1px solid #F4F4F4;
                text-align: center;
                background-color: #F4F4F4;
                padding: 5px;
                border-radius: 4px;
                font-family: "AvenirLTProBook";
                font-weight: 600;
                font-size: 16px;
            }
        }
        .react-trello-card{
            background-color: #FFFFFF;
            border-radius: 5px;
            header{
                display: none;
            }
            .jobCardContainer{
                margin-left: 15px;
                border-radius: 5px;
                background-color: #FFFFFF;
                margin-bottom: 22px;
            
                .jobCardTitle{
                    margin-top: 19px;
                    font-family: "AvenirLTProBook";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #25324b;
                }
                .jobCardEmail{
                        margin-top: 15px;
                        font-size: 15px;
                        font-family: "AvenirLTProBook";
                        font-weight: 600;
                        color: #7C8493;
                        .jobCardParagraphTitle{
                            font-family: "AvenirLTProBook";
                            font-style: "normal";
                            font-size: 14px;
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.85);
                            width: 160px;
                        }
                        .icon-email{
                            font-size: 13px;
                        }
                }
                .jobCardPhone{
                    margin-top: 15px;
                    font-size: 15px;
                    font-family: "AvenirLTProBook";
                    font-weight: 600;
                    color: #7C8493;
                    .jobCardParagraphTitle{
                        font-family: "AvenirLTProBook";
                        font-style: "normal";
                        font-size: 14px;
                        font-weight: 600;
                        color: rgba(53, 15, 15, 0.85);
                        width: 160px;
                    }
                    .icon-phone{
                        font-size: 13px;
                    }
            }
            
            }
         .collapse{
            .jobCardEmail{
                display: flex;
                align-items: center;
                h1{
                    margin-left:7px;
                    font-size: 12px;
                    margin-bottom: 0;
                  
                    
                }
                span{
                    display: none;
                }
            }
            .jobCardPhone{
                display: none;
            }
            button{
                display: none;
            }
         }
        }
     }
}
.viewActivityModalButton {
    border-color: #D9D9D9;
    border-radius: 5px;
    width: 80px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &>span {
        font-weight: 400;
        font-size: 12px;
        padding: 1px 6px;
    }
}

    
.smooth-dnd-container .vertical{
    height: 100%;
}

.yellowLane {
    border-top: 4px solid #FFB836;

    ::before {
        background-color: #FFB836;
    }
}


.purpleLane {
    border-top: 4px solid #7B61FF;

    ::before {
        background-color: #7B61FF
    }
}

.skyBlueLane {
    border-top: 4px solid #26A4FF;

    ::before {
        background-color: #26A4FF;
    }
}


.lightGreenLane {
    border-top: 4px solid #56CDAD;

    ::before {
        background-color: #56CDAD;
    }

}

.orangeLane {
    border-top: 4px solid #FF6550;

    ::before {
        background-color: #FF6550;
    }
}


