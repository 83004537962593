
  .cardWrap{
    label{
      font-weight: 600;
        font-family: "AvenirLTProBook";
        font-size: 14px;
    }
    margin-bottom: 20px;
    .assignCard{
      max-width: 800px;
      width: 100%;
      &>div{
        &>div{
        font-weight: 600;
        font-family: "AvenirLTProBook";
        font-size: 16px;
        }
      }
    }
    &>:first-child{
      &>div{
        &>div{
        font-size: 16px;
        font-weight: 600;
        font-family: "AvenirLTProBook";
        }
      }
    }
  }
  .threShold{
   display: flex;
   justify-content: space-between;
   align-items: center;
   .threSholdInput, .threSholdSwitch{
    :nth-child(2){
      padding-left: 10px;
    }
    flex-direction: row;
    align-items: baseline;
   }
  }
  .deletIcon{
    color: #8C8C8C;
}
