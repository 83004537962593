.collapse {
  :global {
    overflow: hidden;

    .ant-collapse-item {
      .ant-collapse-header {
        background-color: #fff;
        border-radius: 0;

        &.ant-collapse-header {
          border-radius: 0;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        background-color: #1b3799;
        color: #fff;
      }
    }
  }
}
