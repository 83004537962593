.fieldSet {
    border-radius: 5px;
    margin-bottom: 24px;
    border-color:#D9D9D9;

    &>:first-child  {
        min-height: 0;
        border-bottom: 1px solid #D9D9D9;
        &>div{
            &>div{
                font-size: 14px;
                font-weight: 400;
                padding-top: 8px;
                padding-bottom: 5px;
            }
        }
    }

    label {
        font-size: 14px;
    }

    .recurringBtn {
        margin-right: 5px;
        background-color: rgba(0, 0, 0, 0.2);
        color: black;
        border: transparent;

        &.active {
            color: #fff;
            border-color: #1e326e;
            background: #1e326e;
        }

        span {
            font-size: 12px;
        }
    }

    .recurringCheckbox {
        margin: 8px 8px 0px 0px !important;

        &>span {
            &:first-child {
                opacity: 0;
                visibility: hidden;
                position: absolute;
            }

            &:nth-child(2) {
                background-color: #CCC;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}