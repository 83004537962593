h3.cardTitle {
    padding: 30px 30px 30px 0;
    margin-bottom: 0;
    font-size: 16px;
    font-family: "AvenirLTProBook";
    font-weight: 600;

}

.cardWrapper {
    &>:first-child {
        border-bottom: none;
        padding-top: 30px;
        padding-bottom: 0;

        &>div {
            &>div {
                font-size: 16px;
                font-weight: 700;
                padding: 0;
            }
        }

    }


    .billingUnitForm+.billingUnitForm {
        &>:nth-child(2) {
            &>:first-child {
                &>label {
                    margin-top: 30px;
                }
            }

        }
    }

    .billingUnitForm+.billingUnitForm {
        margin-top: 50px;
        border-top: 1px dashed #D9D9D9;
        position: relative;

        &>:nth-child(2) {
            margin-top: 40px;
        }
    }

    .billingUnitRemoveBtn {

        position: absolute;
        right: 25px;
        top: 19px;
        z-index: 1;
    }


    +.cardWrapper {
        margin-top: 20px;
    }

    &>div {
        padding: 30px 24px 24px 24px;
    }

    input {
        max-width: 304px;
        width: 100%;
        border-radius: 5px;
    }

    .cardForm {
        &>div {
            &>:nth-child(2) {

                width: 100%;
            }
        }

    }



    .fieldSet {
        padding: 0px 20px 0px 38px;
        min-inline-size: min-content;
        border-width: 1px;
        border-style: dashed;
        border-color: #278DC0;
        max-width: 609px;
        width: 100%;
        border-radius: 5px;

        .fieldTitle {
            border-width: 1px;
            border-style: dashed;
            border-color: #278DC0;
            color: #278DC0;
            width: 180px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            border-radius: 5px;

        }

        .selectField {
            &>div {
                border-radius: 5px;
            }
        }

        .formIcon {
            align-items: center;
            display: flex;
            justify-content: center;

            path {
                color: #8C8C8C;
            }

            button {
                margin-top: 6px;
                height: 32px;
                width: 32px;

                .deletIcon {
                    color: #8C8C8C;
                }
            }
        }
    }

}

.cardButton {
    margin-top: 30px;
    border-radius: 5px;
}