.oneTimeComponentWrap {
    border: 1px solid #D9D9D9;
    margin-bottom: 20px !important;

    border-radius: 5px;

    .hireFeeTitle {
        color: red;
    }

    .oneTimeHeader {
        display: flex;
        justify-content: space-between;
        padding: 8px 20px 8px 20px;
        align-items: center;



        .oneTimeTitle {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            font-family: "AvenirLTProMedium";

        }

        .addBtn {
            padding: 0 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #0B1E60;


            .icon-plus {
                color: white;
            }
        }
    }

    .headerDvider {
        margin-bottom: 16px;
        margin-top: 0;
        border-color:#D9D9D9;

        
    }

    .hireFeeData {
        padding: 0 20px 0 20px;
    }

    .oneTimeData {
        padding: 0 20px 0 20px;



        .ant-select-selection-placeholder {
            font-weight: 400;
        }

        .anticon {
            font-size: 10px;
        }

        .oneTimeDvider {
            border-color: #278DC0;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }



}