.wrapPageHeader {

    // .clientPageHeader {
    &>div {
        font-family: "AvenirLTProBook";

        &>:nth-child(2) {
            &>span {
                &>span {
                    color: #000000;
                }
            }
        }
    }

    &>:nth-child(2) {
        &>div {
            &>div {
                margin-right: 20px;
                font-size: 20px;
            }

            &>span {
                font-weight: 500;
            }
        }
    }


    .clientButton {
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        font-family: "AvenirLTProBook";

    }

    .backButton {
        background-color: white;
        border-color: #0B1E60 !important;
        color: #0B1E60 !important;

    }



    .nextButton {

        background-color: #0B1E60;
        box-shadow: none;
        border: none;
    }



    // }

}

.editClientCard {
    &>:first-child {
        &>div {
            &>div {
                &>button {
                    margin-right: 80px !important;
                }
            }
        }
    }
}

.saveButton {
    background-color: #0B1E60;
    box-shadow: none;
    border: none;

}

.clientSteps {
    background-color: white;
    padding: 28px 22px;
    margin-bottom: 24px;

    .stepsName {

        &>div {
            &>div{
            &>:nth-child(2) {
                &>span {
                    &>span {
                        background-color: #278DC0 !important;
                    }
                }
            }

            &>:nth-child(3) {


                // @media screen and (min-width:568px) and (max-width:680px) {
                //     width: 15%;
                // }

                &>div {
                    font-weight: 600;
                    font-size: 14px;
                    font-family: "AvenirLTProBook";
                    color: rgba(0, 0, 0, 0.85);
                    width: 153px;
                    white-space: nowrap;
                }

            }
        }
        }

    }


}

h3.cardTitle {
    padding: 8px 30px 20px 0;
    margin-bottom: 0;
    font-size: 16px;
    font-family: "AvenirLTProBook";
    font-weight: 600;

}

.cardWrapper {
    margin-top: 30px;

    label {
        font-family: "AvenirLTProBook";
        font-weight: 600;
        font-size: 14px;
        position: relative;
    }


    input {
        border-radius: 5px;
    }

    .formDatepicker {
        width: 100%;
    }

    .fieldSet {
        padding: 0px 20px 0px 38px;
        min-inline-size: min-content;
        border-width: 1px;
        border-style: dashed;
        border-color: #278DC0;
        max-width: 609px;
        width: 100%;

        .fieldTitle {
            border-width: 1px;
            border-style: dashed;
            border-color: #278DC0;
            color: #278DC0;
            width: 180px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;

        }

        .selectField {
            &>div {
                border-radius: 5px;
            }
        }

        .formIcon {
            align-items: center;
            display: flex;
            justify-content: center;

            path {
                color: #8C8C8C;
            }
        }

    }
}

.deletIcon {
    color: #8C8C8C !important;
}

.clientSaveButton {
    background-color: #0B1E60;
    box-shadow: none;
    border: none;
    margin-top: 30px;

    @media screen and (min-width:576px) {
        position: absolute;
        top: -15px;
        right: 15px;


    }

    @media screen and (max-width:575px) {
        width: 100%;

    }
}

.ContactPersonDepartment {
    @media screen and (min-width:768px) {
        display: flex;
        gap: 20px;
    }

    @media screen and (max-width:767px) {
        &>:first-child {
            margin-bottom: 30px;
        }
    }
}