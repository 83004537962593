.clientBillingCard {
  border-radius: 0;
  border: none;
  .ant-card-head {
    background-color: white;
  }
  .ant-card-extra {
    .clientBilingBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
    }
  }
}

.clientBillingModal {
  margin: 10px 0;
  .clientBillingType {
    font-weight: 400;
    font-size: 16px;
    color: #131523;
  }

  .ant-modal-header {
    border-bottom: none;
    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
      color: #131523;
      font-family: "AvenirLTProBook";
    }
  }

  .ant-modal-body {
    padding-top: 4px;
  }

  .closeIcon {
    .icon-closeable {
      color: #7e84a3;
      font-size: 10px;
    }
  }

  .clientBillingWrap {
    margin-top: 20px;
  }

  .divider {
    margin-top: 36px;
    margin-bottom: 10px;
    color: #f0f0f0;
  }

  .clientHistoryTitle {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .clientBillingTitle {
    .clientOrignalAmount {
      @media screen and (max-width: 337px) {
        margin-top: 20px !important;
      }
    }

    .clientBilling {
      @media screen and (max-width: 360px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 361px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .billingField {
        margin-top: 20px;
        @media screen and (max-width: 360px) {
          margin-top: 0;
        }
        @media screen and (min-width: 361px) {
          display: flex;
          align-items: center;
        }
        h4 {
          margin-top: 0;
        }
        button {
          width: auto;
          height: auto;
        }
      }

      .orignalAmount {
        font-size: 16px !important;
      }

      .cbOrignalAmount {
        font-size: 16px;
        font-weight: 300;
      }

      & > h4 {
        margin-top: 0;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }

      & > div {
        font-size: 18px;
        font-weight: 300;
        color: #262626;
        margin-bottom: 0;
        font-family: "AvenirLTProLight";
      }
    }
  }

  .orignalAmount {
    margin-top: 20px;
    padding-right: 10px;
    margin-bottom: 0;

    @media screen and (max-width: 337px) {
      margin-top: 0 !important;
    }
  }

  .editAmountBtn {
    margin-top: 16px;
    font-size: 14px;

    // @media screen and (max-width:337px) {
    //     margin-top: 0 !important;
    // }
  }

  .clientBillingDetails {
    & > div {
      font-size: 18px;
      font-weight: 300;
      color: #262626;
      margin-bottom: 0;
      font-family: "AvenirLTProLight";
    }
  }
  .clientHistoryWrap {
    max-height: 460px;
    overflow-y: auto;

    .clientHistoryDetail {
      border: 2px solid #dbdde2;
      border-radius: 5px;
      padding: 10px 14px 8px 14px;
      &:not(:first-child) {
        margin-top: 10px;
      }

      .clientAmountHistory {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        .clientDate {
          & > h5 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
          }

          .ant-typography {
            margin-bottom: 0;
          }
        }

        .clientName {
          & > h5 {
            padding-right: 10px;
            font-size: 14px;
            font-weight: 700;
          }

          .ant-typography,
          div {
            margin-bottom: 0;
          }

          & > div {
            font-weight: 500;
          }
        }
      }

      .clientAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        & > h5 {
          font-size: 14px;
          padding-right: 10px;
        }

        .ant-typography {
          margin-bottom: 0;
        }
      }

      .historyDivider {
        margin-top: 10px;
        margin-bottom: 8px;
        color: #f0f0f0;
      }

      .clientAmount {
        & > h5 {
          font-weight: 700;
          padding-right: 10px;
        }

        .ant-typography {
          margin-bottom: 0;
        }

        & > div {
          font-weight: 700;
        }
      }
    }
  }
}

.rag-red {
  color: #e47d6e;
}

.rag-green {
  color: #06a561;
}

.ag-theme-alpine .ag-checkbox-input-wrapper::after {
  border-radius: 2px;
  border: 1px;
}

// .ag-theme-alpine .ag-header-cell-resize::after {
//     background: #ebebeb;
// }
