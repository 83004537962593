.fieldSet {
    padding: 0px 38px 20px 38px;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: dashed;
    border-color: #278DC0;
    max-width: 609px;
    width: 100%;
    border-radius: 5px;

    @media screen and (max-width: 575px) {
        padding: 0px 20px 20px 20px;
    }

    .fieldTitle {
        border-width: 1px;
        border-style: dashed;
        border-color: #278DC0;
        color: #278DC0;
        width: 180px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;

    }

    .selectField {
        &>div {
            border-radius: 5px;
        }
    }

    .ItDepDeleteIcon {
        &>button {

            @media screen and (max-width:575px) {
                width: 100%;
                background: #F4F4F4;
                border-color: #d9d9d9;
                margin-bottom: 16px;
            }
        }
    }

    .formIcon {
        @media screen and (min-width: 575px) {
            margin-top: 30px;
        }

        path {
            color: #8C8C8C;
        }

        button {
            @media screen and (max-width: 575px) {
                width: 100%;
                border: 1px solid;
                border-color: #d9d9d9;
                background: #f5f5f5;

            }

            .deletIcon {
                color: #8C8C8C;
            }
        }
    }
}