.detach-candidate-modal {
  padding: 1.5rem;

  .ant-form {
    .detach-candidate-info {
      margin-bottom: 1.5rem;
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.08);

      .ant-card-body {
        padding: 1.5rem 1rem;

        h1 {
          color: rgba(39, 141, 192, 1);
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: 4px;
        }
        p {
          color: rgba(0, 0, 0, 1);
          margin-bottom: 0;
        }
      }
    }
  }
  .candidate-address {
    h5 {
      font-weight: 700;
      color: black;
    }
    h3 {
      margin-bottom: 0;
      a {
        font-size: 1.125rem;
        font-weight: 700;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .ant-modal-footer {
    padding-top: 1.5rem !important;
  }
}
