.siderPopoverForm {
    max-width: 350px;

    @media screen and (min-width: 992px) {
        // left: 241px !important;
        transform-origin: -4px 50%;
    }

    @media screen and (max-width: 991px) {
        // right: 10px !important;
        padding-right: 1rem;
        padding-top: 0;
    }

    top: 55px !important;
    position: fixed;
    // z-index: 2;

    & > div {
        & > div {
            & > span {
                display: none;
            }
        }

        & > :nth-child(2) {
            border-radius: 14px;

            & > div {
                padding: 24px;
            }
        }
    }
}

.sidebarFixed {
    .siderPopover {
        margin-top: 0;
    }
}

.siderPopover {
    margin: 16px;
    &:hover {
        background-color: #1b3799;
    }

    &:focus {
        background-color: #1b3799;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(.siderPopoverResponsive) {
        width: calc(100% - 32px);
        height: auto;
    }

    background-color: #1b3799;
    color: #fff;
    border-color: transparent;

    padding: 7px 8px;

    .documentIcon {
        display: flex;
        font-size: 24px;
        color: white;
    }

    .wifiIcon {
        display: flex;
        color: #e47d6e;
        font-size: 16px;
    }

    & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        width: calc(100% - 10px);
        margin-left: 7px;
        line-height: 14px;
        text-align: left;
        color: white;
        font-size: 14px;
        font-weight: 400;

        & > :first-child {
            text-align: left;
            color: white;
            font-size: 10px;
            font-weight: 500;
            font-style: normal;

            & > span {
                color: #42c0ff;
            }
        }
    }
}

.siderProfile {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 25px;
    left: 10px;
    background-color: var(--primary-color);

    & > div {
        margin-left: 17px;

        h5 {
            margin: 0;
            color: #fff;
        }

        p {
            margin: 0;
            color: #fff;
            opacity: 0.5;
        }
    }
}

button.siderCloseBtn {
    border-radius: 0;
    width: 100%;
    background: #1e326e;
    height: 54px;
    margin-top: 21px;
}

.collapsedButton {
    &:hover {
        border-color: transparent;
        background-color: #1b3799;
        color: #fff !important;
    }

    &:focus {
        border-color: transparent;
    }

    @media screen and (min-width: 992px) {
        position: absolute;
        right: -12px;
        top: 65px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

        i {
            font-size: 8px !important;
            position: absolute;
            top: 7px;
            left: 8px;
            transform: rotate(-179deg);
        }
    }
}
