// .modalBody {
//   display: flex;
//   justify-content: space-between;
//   gap: 20px;

//   .ant-typography {
//     margin-top: 8px;
//     font-size: 14px;
//     font-weight: 500;
//     font-family: "AvenirLTProBook";
//     color: #979797;
//   }

//   .modalBodyContent {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     width: 100%;

//     .modalBodySwitchbtns {
//       display: flex;
//       justify-content: space-between;

//       .switch {
//         margin-top: 10px;
//         max-width: 32px;
//         width: 100%;
//       }

//       .ant-switch-checked {
//         background-color: #43A047;
//       }

//       .switch1 {
//         margin-top: 10px;
//         width: 32px;
//       }
//     }
//   }

//   .ant-divider {
//     height: 100px;
//   }
// }

.VacancyTitle {
  font-family: "AvenirLTProBook";
}

.modalVacancyContainer {
  display: flex;
  width: 100%;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 16px;
  }

  .modalBodyVacancy {
    border: 1px solid #d6ddeb;
    padding-top: 8px;
    padding-bottom: 11px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 14px;
    text-align: center;

    .ant-typography {
      margin-top: 0px;
    }
  }

  .vacancyNumber {
    color: #278dc0;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0px;
  }

  .vacancyInnerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    gap: 10px;

    .ant-form-item {
      align-items: center;
      margin-top: 15px;

      label {
        font-size: 14px;
        font-family: "AvenirLTProBook";
        color: #161616;
        font-weight: 600;

        &::after {
          content: "";
        }
      }
    }
  }

  .vacancyDate {
    margin-bottom: 0px;
    font-size: 12px;
    color: #7c8493;
    font-family: "AvenirLTProBook";
  }

  .vacancyNumberZero {
    border: 1px solid #d6ddeb;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    min-height: 48px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .totalVacancy {
    font-size: 14px;
    font-family: "AvenirLTProBook";
  }
}

.settingHistoryWrap {
  max-height: 460px;
  overflow-y: auto;

  .settingHistoryDetail {
    border: 2px solid #dbdde2;
    border-radius: 5px;
    padding: 10px 14px 8px 14px;
    &:not(:first-child) {
      margin-top: 10px;
    }

    .settingAmountHistory {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      .clientDate {
        & > h5 {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0;
        }

        .ant-typography {
          margin-bottom: 0;
        }
      }

      .clientName {
        & > h5 {
          padding-right: 10px;
          font-size: 14px;
          font-weight: 700;
        }

        .ant-typography,
        div {
          margin-bottom: 0;
        }

        & > div {
          font-weight: 500;
        }
      }
    }

    .clientAmount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      & > h5 {
        font-size: 14px;
        padding-right: 10px;
      }

      .ant-typography {
        margin-bottom: 0;
      }
    }

    .historyDivider {
      margin-top: 10px;
      margin-bottom: 8px;
      color: #f0f0f0;
    }

    .clientAmount {
      & > h5 {
        font-weight: 700;
        padding-right: 10px;
      }

      .ant-typography {
        margin-bottom: 0;
      }

      & > div {
        font-weight: 700;
      }
    }
  }
}
.swinlaneWrap {
  border: 1px solid #d6ddeb;
  padding: 8px 8px 0 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  & > form {
    & > button {
      margin-bottom: 8px;
    }
  }

  .swinlaneButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    background-color: rgba(236, 236, 236, 0.2);
    border: 1px solid #d6ddeb;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 8px;

    .icon-bars {
      font-size: 6px;
    }

    strong {
      font-size: 14px;
      font-weight: 600;
      color: #25324b;
    }
  }

  .swinlaneForm {
    border: 1px solid #d6ddeb;
    padding: 24px;
    margin-bottom: 8px;

    & > :first-child {
      & > :nth-child(2) {
        margin-bottom: auto;
      }
    }

    .ant-form-item-control {
      padding-left: 0;
      padding-right: 0;
    }

    input {
      background-color: #f4f4f4;
      border-color: #f4f4f4;
    }

    .swinlaneSelect .ant-select-selector {
      background-color: #f4f4f4 !important;
      border: none;
    }

    .reasonLabel {
      .reasonInput {
        border-radius: 4px;
      }
    }
  }

  .addButton {
    border-top: 5px solid #56cdad;
    margin-top: 8px;
    min-height: 58px;
    padding: 13px;
    margin-bottom: 8px;
  }

  .saveButton {
    border-top: 5px solid #56cdad;
    margin-top: 8px;
    min-height: 58px;
    padding: 13px;
  }

  .ant-btn {
    &:hover {
      border-color: #56cdad !important;
    }
  }

  .swinlaneSwitch {
    .ant-row {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
    }

    .ant-col {
      flex: inherit;
      width: auto;
    }

    & > div {
      & > label {
        font-size: 12px;
        font-weight: 400;
        color: #8d8d8d;
      }
    }

    @media screen and (max-width: 767px) {
      margin-left: auto;
      max-width: 84px;
    }

    .ant-form-item-label {
      padding-bottom: 0;
    }

    .ant-form-item-control-input {
      padding-right: 10px;
    }

    /* @media screen and (max-width: 576px) {
      flex-flow: initial;
    } */

    /* .ant-form-item-control {
      @media screen and (max-width: 576px) {
        flex: none !important;
      }
    } */

    /* .ant-switch {
      max-width: 32px;
      width: 100%;
    } */

    .ant-switch-checked {
      background-color: #43a047;
    }

    /* .ant-form-item-label {
      padding: 0;
      order: 2;
    } */

    /* .ant-form-item-control-input {
      padding-left: 12px;

      @media screen and (max-width: 576px) {
        padding-left: 0;
        padding-right: 12px;
      }
    } */
  }

  .reasonLabel {
    label {
      color: #8d8d8d;
      // font-size: 12px;
      // font-weight: 400;
    }
  }
}

.modalSwitches {
  position: relative;

  .ant-form-item-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-form-item {
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;

    .ant-form-item-label,
    .ant-form-item-control {
      padding: 0;

      @media screen and (max-width: 575px) {
        flex: none;
      }
    }

    .ant-form-item-control {
      width: auto;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-control-input {
    align-self: flex-end;
    min-height: auto;

    .ant-switch-checked {
      background-color: #43a047;
    }
  }

  label {
    color: #979797;
    font-family: "AvenirLTProBook";

    &::after {
      content: "";
    }
  }

  &::after {
    content: "";
    width: 1px;
    background-color: #e5e5e5;
    position: absolute;
    height: 95%;
    top: 0;
    left: 50%;

    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}

.divider-1 {
  margin-top: 2px;
}
.submittalNumber {
  color: #278dc0;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0px;
  text-align: center;
}
