.client-ag-table-header {
  margin-bottom: 1rem;
  padding: 1.375rem 1.5rem;
  margin: 0 -1.5rem 1.5rem -1.5rem;
  .ant-page-header-heading-title {
    font-size: 1.5rem;
  }
}
.client-ag-table-card {
  .ant-card-head {
    border-bottom: none;
    .ant-card-head-title {
      padding-bottom: 0;
      .client-search {
        @media screen and(max-width:575px) {
          width: 100% !important;
        }
      }
    }
  }
  .ant-card-body {
    padding: 1rem;
    .client-actions-wrap {
      display: flex;
      justify-content: end;
      gap: 1rem;
      margin-top: 1rem;
      @media screen and(max-width:575px) {
        flex-direction: column;
      }
    }
  }
}
