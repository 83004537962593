.login-container {
  @extend .w-100;
  max-width: 330px;
  margin: auto;
  padding: 3rem 0;
}

.lc-logo {
  @extend .text-center;
  margin-bottom: 3rem;

  img {
    max-width: 200px;
  }
}

.login-card {
  /* @extend .bg-white;
    padding: 24px;
    border-radius: 2px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  h4 {
    @extend .mb-4, .text-center;
    font-size: 20px;
    font-family: "Playfair Display", serif;
  }
}
