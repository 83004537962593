@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?646rxa');
  src:  url('fonts/icomoon.eot?646rxa#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?646rxa') format('truetype'),
    url('fonts/icomoon.woff?646rxa') format('woff'),
    url('fonts/icomoon.svg?646rxa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vector-right:before {
  content: "\e93c";
}
.icon-vector-left:before {
  content: "\e93d";
}
.icon-dropdown-arrow:before {
  content: "\e93a";
}
.icon-detach:before {
  content: "\e939";
}
.icon-view-detail:before {
  content: "\e93b";
}
.icon-del-threshold:before {
  content: "\e938";
}
.icon-commission1:before {
  content: "\e937";
}
.icon-sliderarrow:before {
  content: "\e936";
}
.icon-arrow-right:before {
  content: "\e935";
}
.icon-filter:before {
  content: "\e934";
}
.icon-arrows:before {
  content: "\e92d";
}
.icon-info-circle:before {
  content: "\e92e";
}
.icon-right-arrow:before {
  content: "\e92c";
}
.icon-calender-date:before {
  content: "\e927";
}
.icon-print:before {
  content: "\e928";
}
.icon-plus:before {
  content: "\e926";
}
.icon-Vector:before {
  content: "\e925";
}
.icon-bars-solid:before {
  content: "\e924";
}
.icon-bars:before {
  content: "\e923";
}
.icon-phone:before {
  content: "\e922";
}
.icon-document:before {
  content: "\e91d";
}
.icon-edit1:before {
  content: "\e91e";
}
.icon-lock:before {
  content: "\e91f";
}
.icon-sider-wifi:before {
  content: "\e920";
}
.icon-delet:before {
  content: "\e921";
}
.icon-send:before {
  content: "\e91c";
}
.icon-email:before {
  content: "\e91b";
}
.icon-arrow-down:before {
  content: "\e91a";
}
.icon-back-arrow:before {
  content: "\e919";
}
.icon-calendar:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e917";
}
.icon-order-history:before {
  content: "\e916";
}
.icon-appstore:before {
  content: "\e914";
}
.icon-view:before {
  content: "\e912";
}
.icon-document-search:before {
  content: "\e915";
}
.icon-upload:before {
  content: "\e913";
}
.icon-setting:before {
  content: "\e911";
}
.icon-closeable:before {
  content: "\e910";
}
.icon-vacancies-briefcase:before {
  content: "\e90d";
}
.icon-up-arrow:before {
  content: "\e90b";
}
.icon-invoice:before {
  content: "\e90c";
}
.icon-cancelled-opening:before {
  content: "\e90f";
}
.icon-down-arrow:before {
  content: "\e90e";
}
.icon-commission-plans:before {
  content: "\e902";
}
.icon-breifcase:before {
  content: "\e900";
}
.icon-check-circle:before {
  content: "\e901";
}
.icon-commission:before {
  content: "\e903";
}
.icon-dashboard:before {
  content: "\e904";
}
.icon-invoices:before {
  content: "\e905";
}
.icon-lookup:before {
  content: "\e906";
}
.icon-roles-permissions:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e908";
}
.icon-users:before {
  content: "\e909";
}
.icon-user-square:before {
  content: "\e90a";
}
.icon-candidate:before {
  content: "\e92a";
}
.icon-edit2:before {
  content: "\e929";
}
.icon-candidate-phone:before {
  content: "\e92b";
}
.icon-appCalendar:before {
  content: "\e933";
}
.icon-reload:before {
  content: "\e932";
}
.icon-forward:before {
  content: "\e931";
}
.icon-candidate1:before {
  content: "\e930";
}
.icon-calendar1:before {
  content: "\e92f";
}
