.siderWrap {
  background-color: var(--primary-color);
  position: fixed;
  z-index: 4;

  @media screen and (min-width: 992px) {
    height: 100vh;
  }

  .siderMenu {
    background-color: transparent;
    border-right: 0;
    height: calc(100vh - 200px);
    overflow-x: hidden;
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 5px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    li {
      width: 100%;

      &:after {
        display: none;
      }
    }

    li,
    a,
    i {
      color: #fff;
    }

    li:hover,
    li:hover > div,
    li:hover a,
    li:hover i {
      color: #fff !important;
    }

    li.activeMenuLink,
    li:not(.siderMenuHasSubMenu):hover,
    li:not(.siderMenuHasSubMenu):active {
      background-color: var(--primary-color-hover);
    }

    ul {
      background-color: transparent;
    }

    .siderMenuHasSubMenu {
      &:active > div {
        background-color: transparent;
      }
    }
  }
}

.appContentArea {
  padding: 0 1.5rem 1.5rem;

  @media screen and (max-width: 991px) {
    margin-top: 54px;
  }

  .pageHeader {
    & > div {
      @media screen and (max-width: 480px) {
        flex-direction: column;
      }

      & > span {
        & > div {
          @media screen and (max-width: 480px) {
            width: 100%;
          }

          & > div {
            @media screen and (max-width: 480px) {
              width: 100%;
            }

            .appHeaderBtn {
              @media screen and (max-width: 480px) {
                width: 100%;
              }
            }

            .appHeaderNextBtn:only-child {
              @media screen and (max-width: 480px) {
                width: 100%;
              }
            }

            .appHeaderNextBtn {
              @media screen and (max-width: 480px) {
                width: 50%;
              }
            }

            .appHeaderClientBtn {
              @media screen and (max-width: 480px) {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  .appPageHeader {
    margin: 0 -1.5rem 1.5rem -1.5rem;
    &.clientLookupTable {
      margin: 0;
      margin-top: 1.5rem;
    }

    .addClientBtn {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: "AvenirLTProBook";
      font-weight: 500;
      padding-right: 21px;

      .plusIcon {
        font-size: 18px;
        padding-right: 5px;
      }

      span {
        padding-top: 2px;
      }
    }

    & > div {
      & > div {
        font-family: "AvenirLTProBook";

        span {
          @media screen and (min-width: 576px) {
            font-size: 24px;
          }

          @media screen and (max-width: 575px) {
            font-size: 20px;
          }
        }
      }
    }

    .appPageHeaderDownload {
      color: #0b1e60;
      border: 1.5px solid #0b1e60;
    }

    .appPageHeaderDivider {
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-bottom: 15px;
      margin-top: 0px;
    }
  }

  .rolesPermissionCard {
    & > div {
      & > div {
        & > div {
          width: 100%;
        }
      }
    }

    color: red;
  }

  .appCard {
    & > :first-child {
      padding: 16px;
      border-bottom: 0;

      & > div {
        @media screen and (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }

        & > div {
          font-size: 16px;
          font-weight: 600;
          color: #231f20;
          font-family: "AvenirLTProBook";
          padding: 0;

          @media screen and (max-width: 575px) {
            float: none;
            margin-left: 0;
            width: 100%;
          }
        }
      }
    }

    & > :nth-child(2) {
      padding: 0 16px 16px 16px;

      & > div {
        background-color: white;
      }
    }

    .appListingCardPanel {
      margin-bottom: 6px;
      margin-left: 0px;

      .appListingCardPanelBorder {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        & > div {
          color: #525252;
          padding-bottom: 15px;
          align-items: center;
          font-family: "AvenirLTProBook";
          font-size: 14px;
          font-weight: 500;
          & > span {
            color: rgba(0, 0, 0, 0.2);
            font-weight: 600;
          }
        }
      }
    }

    .appListingCardJobTable {
      display: flex;

      @media screen and (max-width: 400px) {
        flex-wrap: wrap;
      }

      .appListingCardJobbtn {
        margin-right: 16px;

        @media screen and (max-width: 352px) {
          margin-top: 0 !important;
        }
      }

      .appListingCardSearchBar {
        margin-right: 16px;

        @media screen and (max-width: 400px) {
          margin-right: 0 !important;
        }

        // @media screen and (min-width:600px) {
        //   min-width: 264px;
        //   width: 100%;
        // }
      }

      .appListingCardStatusSelect {
        min-width: 104px;
        margin-right: 16px;
        font-size: 14px;
        text-align: center;
        font-family: "AvenirLTProBook";
        color: #161616;

        & > span {
          & > i {
            font-size: 7px;
            padding-top: 3px;
            color: #d4d4d4;
          }
        }

        @media screen and (max-width: 353px) {
          margin-right: 0 !important;
          margin-bottom: 16px;
          width: 100%;
        }
      }

      .appListingCardStatusSelect1 {
        min-width: 150px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "AvenirLTProBook";
        color: #161616;

        & > span {
          & > i {
            font-size: 7px;
            padding-top: 3px;
            color: #d4d4d4;
          }
        }

        @media screen and (max-width: 400px) {
          width: 100%;
          margin-right: 0 !important;
          margin-top: 16px;
        }
      }

      .appListingCardLookUpButton {
        @media screen and (min-width: 0) and (max-width: 400px) {
          margin-top: 16px;
          width: 100%;
        }
      }

      .appListingCardJobTableButtons {
        display: flex;

        @media screen and (max-width: 352px) {
          flex-wrap: wrap;
        }
      }
    }

    .appListingCardSearchBar1 {
      @media screen and (min-width: 576px) {
        min-width: 264px;
      }
    }

    .appListingCardRolesTable {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 10px;
      }

      .appListingCardRolesTableSearch {
        @media screen and (min-width: 576px) {
          max-width: 264px;
          width: 100%;
        }
      }

      .appListingCardRolesTableButton {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 575px) {
          margin-top: 16px;
          width: 100%;
        }

        .plusIcon {
          font-size: 16px;
          padding-right: 9px;
        }

        span {
          font-size: 14px;
          padding-top: 2px;
          font-family: "AvenirLTProBook";
          font-weight: 500;
        }

        @media screen and (max-width: 470px) {
          margin-top: 16px;
          width: 100%;
        }
      }
    }

    .appListingCardActions {
      color: #1890ff;
    }

    .appListingCardStatus {
      border-color: #52c41a;
      color: #52c41a;
    }
  }

  .appListingCardContainer {
    border-bottom: 0 !important;
    border-radius: 5px !important;
    margin-bottom: 24px;

    & > :first-child {
      font-size: 18px;
      font-weight: 600;
      color: #231f20 !important;
      font-family: "AvenirLTProBook";
      padding-top: 16px;
      padding-bottom: 16px;

      & > i {
        font-size: 10px !important;
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }

    & > :nth-child(2) {
      & > div {
        padding: 0 !important;
      }
    }

    .appListingCardContainerActions {
      color: #1890ff;
    }
  }

  .appListingCardSubmitBilling {
    // border-radius: 4px;

    & > :first-child {
      padding: 0 18px;
      border-bottom: 0;
      background-color: #fafafa;

      & > div {
        & > div {
          font-size: 20px;
          font-weight: 600;
          color: #231f20;
          font-family: "AvenirLTProBook";
          padding: 20px 0;
        }
      }
    }

    & > :nth-child(2) {
      padding: 0 3px;
    }
  }

  .invoicesButton {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .clientLookupSelect {
      max-width: 200px;
      width: 100%;
    }

    .invoicesButtonSet {
      width: 128px;
      text-align: center;
      font-size: 14px;
      font-family: "AvenirLTProBook";
      font-weight: 400;

      & > div {
        background-color: transparent;
      }
    }
  }

  .clientBillingRulesButton {
    margin-top: 20px;
  }
}

.clientsPageHeader {
  & > div {
    & > div {
      & > span {
        color: #25324b;
      }
    }
  }
}

.userCardHeaderWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .userLogCardSearchBar {
    width: 264px;

    @media screen and (max-width: 575px) {
      margin-bottom: 16px;
      width: 100%;
    }
  }

  .userLogCardHeader {
    @media screen and (min-width: 576px) {
      & > :first-child {
        margin-right: 16px;
      }
    }

    .userLogCardSelect {
      @media screen and (min-width: 768px) {
        width: 128px;
      }

      @media screen and (max-width: 575px) {
        width: 100%;
        margin-bottom: 16px;
      }

      & > :first-child {
        & > :nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          color: #161616;
        }
      }

      // &>:nth-child(2) {
      //   font-size: 10px !important;
      // }
    }
  }

  .userLogButton {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}

// .settingBtn {
//   width: 32px;
//   padding: 6px;

//   @media screen and (max-width:352px) {
//     margin-top: 0 !important;

//   }

//   .settingIcon {
//     font-size: 17px;
//     color: #8C8C8C;

//   }
// }

.clientsJobPageHeader {
  & > :first-child {
    & > :nth-child(2) {
      @media screen and (max-width: 523px) {
        width: 100%;
      }
    }
  }

  & > div {
    align-items: center;

    & > div {
      flex-wrap: wrap;

      & > span {
        align-self: center;
      }

      & > div {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 18px;
        margin-bottom: -19px;
        align-self: center;
      }

      & > :first-child {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.4;
        font-family: "AvenirLTProBook";
      }

      & > :last-child {
        font-size: 16px !important;
        display: block;
        width: calc(100% - 50px);
        margin-left: 40px;
        font-family: "AvenirLTProBook";
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}

.responsiveLayoutHeader {
  @media print {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  background-color: #0b1e60 !important;
  gap: 20px;
  align-items: center;
  padding: 0 1.5rem;
  height: 54px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.sidebarFixed {
  // margin-top: 54px;
  box-shadow: none;
}

.site-layout {
  @media screen and (min-width: 992px) {
    flex-grow: 1;
    margin-left: 250px;
  }
}

.appListingCardCommissionPlansTAble {
  & > :first-child {
    padding: 0 0px;
    border-bottom: 0;

    & > div {
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        // padding-top: 10px;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        // padding-top: 10px;
      }

      & > div {
        font-size: 16px;
        font-weight: 600;
        color: #231f20;
        font-family: "AvenirLTProMedium";

        @media screen and (max-width: 767px) {
          float: none;
          margin-left: 0;
          padding-top: 0;
          width: 100%;
        }

        @media screen and (max-width: 991px) {
          float: none;
          margin-left: 0;
          padding-top: 0;
          width: 100%;
        }
      }
    }
  }

  & > :nth-child(2) {
    padding: 0 16px 16px 16px;

    & > div {
      background-color: white;
    }
  }
}

.appListingCardJobTableButtons {
  .appListingCardStatusSelect {
    font-size: 10px;
  }
}

// * Temporary styling

.cardWrapper {
  label {
    font-family: "AvenirLTProBook";
    font-weight: 600;
    font-size: 14px;
  }

  & > div {
    padding-right: 62px;
  }

  input {
    border-radius: 5px;
  }

  .formDatepicker {
    width: 100%;
  }

  .fieldSet {
    padding: 0px 20px 0px 38px;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: dashed;
    border-color: #278dc0;
    max-width: 609px;
    width: 100%;

    .fieldTitle {
      border-width: 1px;
      border-style: dashed;
      border-color: #278dc0;
      color: #278dc0;
      width: 180px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
    }

    .selectField {
      & > div {
        border-radius: 5px;
      }
    }

    .formIcon {
      align-items: center;
      display: flex;
      justify-content: center;

      path {
        color: #8c8c8c;
      }

      button {
        margin-top: 6px;
        height: 32px;
        width: 32px;

        .deletIcon {
          color: #8c8c8c;
        }
      }
    }
  }
}
