.billing-rule-tab {
  .ant-tabs-nav-list {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d0d5dd;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    margin: 0;
    padding: 10px 10px 9px;
    background-color: #fff;
    min-width: 100px;
    justify-content: center;
    & + .ant-tabs-tab {
      border-left: 1px solid #d0d5dd;
    }
  }
}

.tier-wrap {
  @media screen and (min-width: 768px) {
    padding: 30px 40px;
  }
  .ant-checkbox-inner {
    border: 1px solid #278dc0;
    background-color: #f1faff;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    top: 6px;
    border-color: #278dc0;
  }
  &:first-child {
    padding-left: 0;
  }
  & + .tier-wrap {
    @media screen and (min-width: 768px) {
      border-left: 1px solid #d9d9d9;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 0;
    }
  }
}

.effective-date {
  padding: 14px 20px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  // .ant-row {
  //   align-items: center;
  // }
  label {
    flex-direction: row;
    font-weight: 700;
    &:after {
      margin-right: 15px;
    }
  }
  .effective-date-picker {
    max-width: 290px;
    width: 100%;
  }
  .ant-form-item-required {
    flex-direction: row;
  }
}

.date-updated {
  font-size: 14px !important;
  color: #979797 !important;
  text-align: right;
  margin-top: 10px;
}
