.activityItem {
  .activityHeader {
    color: #278dc0;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    font-family: "AvenirLTProHeavy";
  }

  .activityMessage {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: "AvenirLTProMedium";
  }

  .activityTimestamp {
    .activityIcon {
      color: #8c8c8c;
      font-size: 14px;
    }

    .dateInfo {
      color: #0b1e60;
      font-weight: 500;
      font-size: 12px;
      margin-left: 12px;
      font-family: "AvenirLTProMedium";
    }
  }
}

.divider {
  margin-top: 10px;
  margin-bottom: 20px;
}



