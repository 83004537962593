@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");
$primary: #1e2144;
$text-primary: $primary;
@import "assets/fonts/stylesheet.css";
@import "assets/fonticons/style.css";
//@import "assets/scss/theme.less";
@import "./assets/scss/utilities";
@import "./assets/scss/global";
@import "./assets/scss/login";
// @import "./assets/scss/slick.scss";
// @import "./assets/scss/slick-theme.scss";